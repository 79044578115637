import { Model } from "@vuex-orm/core";

export default class DevisCommunication extends Model {
  static get entity() {
    return "devisCommunications";
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      phase: this.attr(null),
      files: this.attr(null),
      content: this.attr(null),
      sentDate: this.attr(null),
      sentTo: this.attr(null),
      dossierId: this.attr(null),
      countFiles: this.attr(null),
      hiddenCommunication: this.attr(null),
      offerAccessHash: this.attr(null),
    };
  }
}
