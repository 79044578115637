import DossierCompanyMaterial from "../models/DossierCompanyMaterial";
import API from "@/api/dossierCompaniesMaterial";
import { getLotPosition } from "@/hooks/dossierCompany";

function getDefaultState() {
  return {
    countLots: 20,
  };
}
function getPosition(data) {
  let position = data.position || 0;
  if (data.position === 0) {
    if (data.rowData.lots) {
      position = getLotPosition(data.rowData.lots[0]);

      data.rowData.lots.forEach(lot => {
        const lotPosition = getLotPosition(lot);
        if (position > lotPosition) {
          position = lotPosition;
        }
      });
    }
  }

  return position;
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        res.data.forEach(item => {
          const displayPosition = getPosition(item);
          item.displayPosition = displayPosition;
          DossierCompanyMaterial.insertOrUpdate({
            data: item,
          });
        });
      });
    },
    createAPI({ state }, payload) {
      return API.create(payload).then(res => {
        const length = DossierCompanyMaterial.query().get().length;
        res.data.displayPosition = length + state.countLots;
        DossierCompanyMaterial.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data, payload.opts).then(res => {
        DossierCompanyMaterial.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id, payload.opts).then(() => {
        DossierCompanyMaterial.delete(payload.id);
      });
    },
    updateSyncData(_, payload) {
      return API.updateSyncData(payload.id, payload.data).then(res => {
        DossierCompanyMaterial.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.displayPosition || 0;
          const valueB = b.displayPosition || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
  },
};
