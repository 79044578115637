import { Model } from "@vuex-orm/core";

export default class Company extends Model {
  static get entity() {
    return "companies";
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      siren: this.attr(null),
      gdprAgreement: this.attr(null),
      informations: this.attr(null),
      otherName: this.attr(null),
    };
  }
}
