import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(dossierOfferId) {
    let params = {};
    if (dossierOfferId) {
      params["dossierOfferId"] = dossierOfferId;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_OFFER_INSURER_COMMUNICATIONS_LINK() +
        (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  create(data) {
    return axios.post(links.DOSSIER_OFFER_INSURER_COMMUNICATIONS_LINK(), data);
  },
  update(id, data) {
    return axios.put(links.DOSSIER_OFFER_INSURER_COMMUNICATION_LINK(id), data);
  },
  delete(id) {
    return axios.delete(links.DOSSIER_OFFER_INSURER_COMMUNICATION_LINK(id));
  },
  sendMessage(id, content) {
    return axios.put(links.DOSSIER_OFFER_INSURER_COMMUNICATION_SEND_MESSAGE(id), { content });
  },
};
