import axios from "axios";
import links from "@/links";
import qs from "qs";
import { INSURERS_ITEMS_PER_PAGE } from "../constants/insurers";

export default {
  getInsurers(
    search,
    pagination = true,
    page = 1,
    itemsPerPage = INSURERS_ITEMS_PER_PAGE,
    sortBy = "createdAt",
    sortOrder = "desc"
  ) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }
    params[`order[${sortBy}]`] = sortOrder;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.INSURERS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },

  getInsurer(id) {
    return axios.get(links.INSURER_LINK(id));
  },
  deleteInsurer(id) {
    return axios.delete(links.INSURER_DELETE(id));
  },
  put(id, formData) {
    return axios.put(links.INSURER_LINK(id), formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  create(formData) {
    return axios.post(links.INSURERS_LINK(), formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getFile(filename) {
    const params = {
      filename,
    };
    return axios.get(`${links.INSURER_FILES_LINK()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },
};
