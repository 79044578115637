import API from "@/api/messaging";
import * as db from "../database";

async function insertOrUpdateTargetedInstance(modelName, data) {
  const model = db[modelName];
  model.insertOrUpdate({
    data,
  });
}

export default {
  namespaced: true,
  state() {
    return {
      isLoadingSendMessage: false,
      sendMessageSuccess: false,
      sendMessageError: false,
    };
  },
  getters: {
    getIsLoadingSendMessage(state) {
      return state.isLoadingSendMessage;
    },
    getSendMessageSuccess(state) {
      return state.sendMessageSuccess;
    },
    getSendMessageError(state) {
      return state.sendMessageError;
    },
  },
  mutations: {
    setIsLoadingSendMessage(state, value) {
      state.isLoadingSendMessage = value;
    },
    setSendMessageSuccesss(state, value) {
      state.sendMessageSuccess = value;
    },
    setSendMessageError(state, value) {
      state.sendMessageError = value;
    },
  },
  actions: {
    setIsLoadingSendMessage({ commit }, value) {
      commit("setIsLoadingSendMessage", value);
    },
    setSendMessageSuccesss({ commit }, value) {
      commit("setSendMessageSuccesss", value);
    },
    setSendMessageError({ commit }, value) {
      commit("setSendMessageError", value);
    },
    sendMessage({ dispatch }, payload) {
      const { targetedInstanceModel, targetedInstanceId, content, uid, opts } = payload;

      return new Promise((resolve, reject) => {
        dispatch("setIsLoadingSendMessage", true);
        dispatch("setSendMessageSuccesss", false);
        dispatch("setSendMessageError", false);
        API.sendMessage(targetedInstanceModel, targetedInstanceId, content, uid, opts)
          .then(async res => {
            await insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
            dispatch("setSendMessageSuccesss", true);
            dispatch("setIsLoadingSendMessage", false);
            resolve(res);
          })
          .catch(err => {
            dispatch("setSendMessageError", true);
            dispatch("setIsLoadingSendMessage", false);
            reject(err);
          });
      });
    },

    create(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, opts } = payload;
      return new Promise((resolve, reject) => {
        API.create(targetedInstanceModel, targetedInstanceId, opts)
          .then(async res => {
            await insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    update(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, content, uid, opts } = payload;

      return new Promise((resolve, reject) => {
        API.update(targetedInstanceModel, targetedInstanceId, content, uid, opts).catch(err => {
          reject(err);
        });
      });
    },

    remove(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, uid, opts } = payload;

      return new Promise((resolve, reject) => {
        API.remove(targetedInstanceModel, targetedInstanceId, uid, opts)
          .then(async res => {
            await insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    async updateStore(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, content, uid } = payload;
      const data = db[targetedInstanceModel].find(targetedInstanceId);
      let communicationData = JSON.parse(JSON.stringify(data.communicationData));
      console.log(data.communicationData, communicationData);
      let communicationDataItem = communicationData.find(item => item.uid === uid);
      communicationDataItem.content = content;
      var foundIndex = communicationData.findIndex(item => item.uid === uid);
      communicationData[foundIndex] = communicationDataItem;
      data.communicationData = communicationData;
      await insertOrUpdateTargetedInstance(targetedInstanceModel, data);
    },
  },
};
