import DossierExport from "../models/DossierExport";
import API from "@/api/dossierExports";
import { DOSSIER_EXPORT_ITEMS_PER_PAGE } from "@/constants/dossierExport";
import Insurer from "../models/Insurer";

function getDefaultState() {
  return {
    paginationOptions: {},
    itemsPerPage: DOSSIER_EXPORT_ITEMS_PER_PAGE,

    hasMoreDossierExports: {},
    totalItems: {},
    page: {},
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    listCallPerformed(state, { page, totalItems, status, value }) {
      state.page[status] = page || 1;
      state.totalItems[status] = totalItems || 0;
      if (state.hasMoreDossierExports[status]) {
        state.hasMoreDossierExports[status] = state.hasMoreDossierExports[status] + value;
      } else {
        state.hasMoreDossierExports[status] = value;
      }
    },

    resetPaginationOptions(state) {
      Object.keys(state.hasMoreDossierExports).forEach(key => {
        delete state.hasMoreDossierExports[key];
      });
      Object.keys(state.totalItems).forEach(key => {
        delete state.totalItems[key];
      });
    },
  },
  actions: {
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierExport.insertOrUpdate({
          data: res.data,
        });
      });
    },
    getDossierExports({ commit, state }, payload) {
      return API.getDossierExports(
        payload.pagination,
        payload.page,
        state.itemsPerPage,
        payload.sortBy,
        payload.sortOrder,
        payload.type
      ).then(res => {
        DossierExport.insertOrUpdate({
          data: res.data.items,
        });

        Insurer.insertOrUpdate({
          data: res.data.insurers,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
          status: payload.type,
          value: res.data.items.length,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierExport.delete(payload.id);
      });
    },
    sendEmail(_, payload) {
      return API.sendEmail(payload.id).then(res => {
        DossierExport.insertOrUpdate({
          data: res.data,
        });
      });
    },

    sendingOneDrive(_, payload) {
      return API.sendingOneDrive(payload.id).then(res => {
        DossierExport.insertOrUpdate({
          data: res.data,
        });
      });
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
  },
  getters: {
    getMultipleForDossierExportByStatus:
      state =>
      (type, sortBy = "updatedAt", sortOrder = "desc") => {
        return Object.values(state.data)
          .filter(dossierExport => dossierExport.type === type)
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (valueA === valueB) {
              return a.id < b.id ? 1 : -1;
            } else if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(dossierExport => ({ ...dossierExport }));
      },

    getPage: state => status => {
      return state.page[status];
    },
    getTotalItems: state => status => {
      return Number(state.totalItems[status]);
    },
    getCurrentItemsInStore: state => status => {
      return state.hasMoreDossierExports[status];
    },
  },
};
