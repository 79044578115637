const commonGlobalRoutes = () => {
  return [
    {
      path: "/mentions-legales",
      name: "route.mentions.legales",
      component: function () {
        return import(
          /* webpackChunkName: "insurance" */ "@/vue/views/common/MentionsLegalesView.vue"
        );
      },
    },
    {
      path: "/documents",
      name: "route.documents",
      component: function () {
        return import(
          /* webpackChunkName: "insurance" */ "@/vue/views/common/InsurersDocuments.vue"
        );
      },
    },
    {
      path: "/questionnaire/:accessHash",
      name: "route.questionnaire",
      component: function () {
        return import(
          /* webpackChunkName: "insurance" */ "@/vue/views/common/QuestionnaireView.vue"
        );
      },
    },
    {
      path: "/questionnaire/merci/:accessHash",
      name: "route.questionnaire.success",
      component: () => {
        return import(
          /* webpackChunkName: "questionnaire" */ "@/vue/views/common/QuestionnaireSuccessView.vue"
        );
      },
    },

    {
      path: "/reception/questionnaire/:accessHash",
      name: "route.reception.questionnaire",
      component: function () {
        return import(
          /* webpackChunkName: "reception-questionnaire" */ "@/vue/views/common/ReceptionQuestionnaireView.vue"
        );
      },
    },
    {
      path: "/reception/questionnaire/merci/:accessHash",
      name: "route.reception.questionnaire.success",
      component: () => {
        return import(
          /* webpackChunkName: "questionnaire" */ "@/vue/views/common/ReceptionQuestionnaireSuccessView.vue"
        );
      },
    },
  ];
};

export { commonGlobalRoutes };
