import { Model } from "@vuex-orm/core";
import Dossier from "./Dossier";
import DossierOffer from "./DossierOffer";

export default class DossierOfferSet extends Model {
  static get entity() {
    return "dossierOfferSets";
  }

  static fields() {
    return {
      id: this.attr(null),
      sentDate: this.attr(null),
      dossierId: this.attr(null),
      dossier: this.belongsTo(Dossier, "dossierId"),
      sendingChannel: this.attr(null),
      recipient: this.attr(null),
      dossierOffers: this.hasMany(DossierOffer, "dossierOfferSetId"),
      status: this.attr(null),
      accessHash: this.attr(null),
      subscriptionData: this.attr(null),
    };
  }
}
