import Lead from "../models/Lead";
import API from "@/api/leads";

function getDefaultState() {
  return {
    paginationOptions: {},

    leadStatus: null,
    leadPartner: null,
    leadDateStart: null,
    leadDateEnd: null,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    listCallPerformed(state, { page, totalItems, totalFilteredItems, statistics }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
        totalFilteredItems: totalFilteredItems || 0,
        statistics: statistics || [],
      });
    },

    updateLeadStatusFilter(state, value) {
      state.leadStatus = value;
    },
    updateLeadPartnerFilter(state, value) {
      state.leadPartner = value;
    },
    updateLeadDateStartFilter(state, value) {
      state.leadDateStart = value;
    },
    updateLeadDateEndFilter(state, value) {
      state.leadDateEnd = value;
    },

    resetPaginationOptions(state) {
      Object.keys(state.paginationOptions).forEach(key => {
        delete state.paginationOptions[key];
      });
    },
    resetFilterOptions(state) {
      state.leadStatus = null;
      state.leadPartner = null;
      state.leadDateStart = null;
      state.leadDateEnd = null;
    },
    updateLeadPeriodFilter(state, value) {
      state.leadDateStart = value.after;
      state.leadDateEnd = value.before;
    },
  },
  actions: {
    fetchLeads({ commit }, payload = {}) {
      return API.fetchLeads(payload.pagination, payload.page, payload.itemsPerPage, {
        status: payload.status,
        partner: payload.partner,
        leadDateStart: payload.leadDateStart,
        leadDateEnd: payload.leadDateEnd,
      }).then(res => {
        Lead.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalFilteredItems: res.data.totalFilteredItems,
          totalItems: res.data.totalItems,
          statistics: res.data.statistics,
        });
      });
    },

    updateLeadStatusFilter({ commit }, value) {
      commit("updateLeadStatusFilter", value);
    },
    updateLeadPartnerFilter({ commit }, value) {
      commit("updateLeadPartnerFilter", value);
    },
    updateLeadDateStartFilter({ commit }, value) {
      commit("updateLeadDateStartFilter", value);
    },
    updateLeadDateEndFilter({ commit }, value) {
      commit("updateLeadDateEndFilter", value);
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
    resetFilterOptions({ commit }) {
      commit("resetFilterOptions");
    },
    updateLeadPeriodFilter({ commit }, value) {
      commit("updateLeadPeriodFilter", value);
    },
  },
  getters: {
    getMultipleForLeadsList:
      state =>
      (sortBy = "id", sortOrder = "desc") => {
        return Object.values(state.data)
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(lead => ({ ...lead }));
      },

    getPage: state => state.paginationOptions.page,
    getTotalItems: state => state.paginationOptions.totalItems,
    getTotalFilteredItems: state => state.paginationOptions.totalFilteredItems,
    getStatistics: state => state.paginationOptions.statistics,

    getLeadStatus: state => state.leadStatus,
    getLeadPartner: state => state.leadPartner,
    getLeadDateStart: state => state.leadDateStart,
    getLeadDateEnd: state => state.leadDateEnd,
  },
};
