import { Database } from "@vuex-orm/core";
const database = new Database();

// models
import Company from "./models/Company";
import DossierAdditionalInfo from "./models/DossierAdditionalInfo";
import DossierAdministrativeDocument from "./models/DossierAdministrativeDocument";
import DossierCompanyBuilding from "./models/DossierCompanyBuilding";
import DossierCompanyMaterial from "./models/DossierCompanyMaterial";
import DossierCompanyTechnical from "./models/DossierCompanyTechnical";
import DossierContract from "./models/DossierContract";
import DossierInsurance from "./models/DossierInsurance";
import DossierMission from "./models/DossierMission";
import DossierMissionDocument from "./models/DossierMissionDocument";
import DossierOffer from "./models/DossierOffer";
import DossierOfferSet from "./models/DossierOfferSet";
import DossierPayment from "./models/DossierPayment";
import DossierSignature from "./models/DossierSignature";
import DossierSpecificDocument from "./models/DossierSpecificDocument";
import DossierPreInsurancePayment from "./models/DossierPreInsurancePayment";
import Insurer from "./models/Insurer";
import NotificationLog from "./models/NotificationLog";
import User from "./models/User";
import Dossier from "./models/Dossier";
import Lead from "./models/Lead";
import DossierQuestionnaire from "./models/DossierQuestionnaire";

import DossierOfferInsurerCommunication from "./models/DossierOfferInsurerCommunication";
import InsurerCommunication from "./models/InsurerCommunication";

import DossierESCircuit from "./models/DossierESCircuit";

import DossierExport from "./models/DossierExport";

import DossierTrashedDocument from "./models/DossierTrashedDocument";
import DevisCommunication from "./models/DevisCommunication";
import BackupLogging from "./models/BackupLogging";
import DossierArchived from "./models/DossierArchived";

import UnsubscribedEmail from "./models/UnsubscribedEmail";

// modules
import companies from "./modules/companies";
import dossierAdditionalInfo from "./modules/dossierAdditionalInfo";
import dossierAdministrativeDocuments from "./modules/dossierAdministrativeDocuments";
import dossierCompaniesBuilding from "./modules/dossierCompaniesBuilding";
import dossierCompaniesMaterial from "./modules/dossierCompaniesMaterial";
import dossierCompaniesTechnical from "./modules/dossierCompaniesTechnical";
import dossierContracts from "./modules/dossierContracts";
import dossierInsurances from "./modules/dossierInsurances";
import dossierMissions from "./modules/dossierMissions";
import dossierMissionDocuments from "./modules/dossierMissionDocuments";
import dossierOffers from "./modules/dossierOffers";
import dossierOfferSets from "./modules/dossierOfferSets";
import dossierPayments from "./modules/dossierPayments";
import dossierSignatures from "./modules/dossierSignatures";
import dossierSpecificDocuments from "./modules/dossierSpecificDocuments";
import dossierPreInsurancePayments from "./modules/dossierPreInsurancePayments";
import insurers from "./modules/insurers";
import notificationLogs from "./modules/notificationLogs";
import users from "./modules/users";
import dossiers from "./modules/dossiers";
import leads from "./modules/leads";
import dossierQuestionnaires from "./modules/dossierQuestionnaires";

import dossierOfferInsurerCommunications from "./modules/dossierOfferInsurerCommunications";
import insurerCommunications from "./modules/insurerCommunications";

import dossierESCircuits from "./modules/dossierESCircuits";
import UserActionLog from "./models/UserActionLog";
import userActionLogs from "./modules/userActionLogs";
import dossierExports from "./modules/dossierExports";

import dossierTrashedDocuments from "./modules/dossierTrashedDocuments";
import devisCommunications from "./modules/devisCommunications";
import backupLogging from "./modules/backupLogging";
import dossierArchived from "./modules/dossierArchived";

import unsubscribedEmails from "./modules/unsubscribedEmails";

database.register(Company, companies);
database.register(DossierAdditionalInfo, dossierAdditionalInfo);
database.register(DossierAdministrativeDocument, dossierAdministrativeDocuments);
database.register(DossierCompanyBuilding, dossierCompaniesBuilding);
database.register(DossierCompanyMaterial, dossierCompaniesMaterial);
database.register(DossierCompanyTechnical, dossierCompaniesTechnical);
database.register(DossierContract, dossierContracts);
database.register(DossierInsurance, dossierInsurances);
database.register(DossierMission, dossierMissions);
database.register(DossierMissionDocument, dossierMissionDocuments);
database.register(DossierOffer, dossierOffers);
database.register(DossierOfferSet, dossierOfferSets);
database.register(DossierPayment, dossierPayments);
database.register(DossierSignature, dossierSignatures);
database.register(DossierSpecificDocument, dossierSpecificDocuments);
database.register(DossierPreInsurancePayment, dossierPreInsurancePayments);
database.register(Insurer, insurers);
database.register(NotificationLog, notificationLogs);
database.register(User, users);
database.register(Dossier, dossiers);
database.register(Lead, leads);
database.register(DossierQuestionnaire, dossierQuestionnaires);
database.register(UserActionLog, userActionLogs);

database.register(DossierOfferInsurerCommunication, dossierOfferInsurerCommunications);
database.register(InsurerCommunication, insurerCommunications);

database.register(DossierESCircuit, dossierESCircuits);

database.register(DossierExport, dossierExports);

database.register(DossierTrashedDocument, dossierTrashedDocuments);
database.register(DevisCommunication, devisCommunications);
database.register(BackupLogging, backupLogging);
database.register(DossierArchived, dossierArchived);

database.register(UnsubscribedEmail, unsubscribedEmails);

export {
  database,
  // ..
  Company,
  DossierAdditionalInfo,
  DossierAdministrativeDocument,
  DossierCompanyBuilding,
  DossierCompanyMaterial,
  DossierCompanyTechnical,
  DossierContract,
  DossierInsurance,
  DossierMission,
  DossierMissionDocument,
  DossierOffer,
  DossierOfferSet,
  DossierPayment,
  DossierSignature,
  DossierSpecificDocument,
  DossierPreInsurancePayment,
  Insurer,
  NotificationLog,
  User,
  Dossier,
  Lead,
  DossierQuestionnaire,
  DossierOfferInsurerCommunication,
  InsurerCommunication,
  DossierESCircuit,
  DossierExport,
  DossierTrashedDocument,
  DevisCommunication,
  BackupLogging,
  DossierArchived,
  UnsubscribedEmail,
};
