import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(dossierId) {
    let params = {};
    if (dossierId) {
      params["dossierId"] = dossierId;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_INSURANCES_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  create(data) {
    return axios.post(links.DOSSIER_INSURANCES_LINK(), data);
  },
  update(id, data) {
    return axios.put(links.DOSSIER_INSURANCE_LINK(id), data);
  },
  delete(id) {
    return axios.delete(links.DOSSIER_INSURANCE_LINK(id));
  },
  sendCertificate(id) {
    return axios.post(links.DOSSIER_INSURANCE_SEND_DOCUMENT_CERTIFICATE_LINK(id));
  },
};
