import { Model } from "@vuex-orm/core";

export default class DossierOfferInsurerCommunication extends Model {
  static get entity() {
    return "dossierOfferInsurerCommunications";
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      files: this.attr(null),
      content: this.attr(null),
      sentDate: this.attr(null),
      dossierOfferId: this.attr(null),
    };
  }
}
