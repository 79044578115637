import DossierTrashedDocument from "../models/DossierTrashedDocument";
import API from "@/api/dossierTrashedDocuments";

function getDefaultState() {
  return {
    paginationOptions: {},
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    listCallPerformed(state, { page, totalItems }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
      });
    },

    resetPaginationOptions(state) {
      Object.keys(state.paginationOptions).forEach(key => {
        delete state.paginationOptions[key];
      });
    },
  },
  actions: {
    fetch({ commit }, payload = {}) {
      return API.fetch(payload.pagination, payload.page, payload.itemsPerPage, {
        dossierId: payload.dossierId,
      }).then(res => {
        DossierTrashedDocument.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
        });
      });
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
  },
  getters: {
    getMultipleForList:
      state =>
      (sortBy = "id", sortOrder = "desc") => {
        return Object.values(state.data)
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(dossierTrashedDocument => ({ ...dossierTrashedDocument }));
      },

    getPage: state => state.paginationOptions.page,
    getTotalItems: state => state.paginationOptions.totalItems,
  },
};
