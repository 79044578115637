export default {
  namespaced: true,
  state() {
    return {
      isLoadingFetch: false,
    };
  },
  mutations: {
    setIsLoadingFetch(state, value) {
      state.isLoadingFetch = value;
    },
  },
  actions: {
    setIsLoadingFetch({ commit }, value) {
      commit("setIsLoadingFetch", value);
    },
  },
  getters: {
    getIsLoadingFetch(state) {
      return state.isLoadingFetch;
    },
  },
};
