import API from "@/api/insurerCommunicationZipFiles";
import InsurerCommunication from "../database/models/InsurerCommunication";

export default {
  namespaced: true,
  actions: {
    uploadFile(_, payload) {
      const { insurerCommunicationId, formData } = payload;
      return API.uploadFile(insurerCommunicationId, formData).then(res => {
        InsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteFile(_, payload) {
      const { insurerCommunicationId, filename } = payload;
      return API.deleteFile(insurerCommunicationId, filename).then(res => {
        InsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
};
