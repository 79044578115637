import User from "../database/models/User";
import API from "@/api/employees";
import { userRoles } from "@/constants/users";

function getDefaultState() {
  return {
    activeSearchTerm: "",

    hasMoreEmployees: null,
    cursorForPagination: null,

    statistics: {},
  };
}

export default {
  namespaced: true,
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, value) {
      state.activeSearchTerm = value;
    },

    setHasMoreEmployees(state, value) {
      state.hasMoreEmployees = value;
    },
    setCursorForPagination(state, value) {
      state.cursorForPagination = value;
    },
    resetPaginationOptions(state) {
      state.hasMoreEmployees = null;
      state.cursorForPagination = null;
    },
    setStatistics(state, value) {
      state.statistics = value;
    },
  },
  actions: {
    fetchEmployees({ getters, commit }, payload) {
      const cursorForPagination = getters.getCursorForPagination;
      const cursorId = cursorForPagination ? cursorForPagination.id : undefined;

      return API.getEmployees(payload.search, cursorId, 0, payload.itemsPerPage).then(res => {
        const { items, hasMore } = res.data;

        User.insertOrUpdate({
          data: items,
        });

        const lastItemInResponse = items[items.length - 1];
        commit("setCursorForPagination", lastItemInResponse);

        commit("setHasMoreEmployees", hasMore);
      });
    },
    fetchEmployeesAutocomplete(_, payload) {
      return new Promise((resolve, reject) => {
        API.getEmployees(payload.search, undefined, 1)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEmployee(_, payload) {
      return API.getEmployee(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    createEmployee(_, payload) {
      return API.createEmployee(payload).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateEmployee(_, payload) {
      return API.updateEmployee(payload).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    deactivateAccount(_, payload) {
      return API.deactivateAccount(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    deleteAccount(_, payload) {
      return API.deleteAccount(payload.id).then(() => {
        User.delete(payload.id);
      });
    },

    activateAccount(_, payload) {
      return API.activateAccount(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
    deleteAll() {
      User.delete(
        user =>
          !!user.roles &&
          (user.roles.includes(userRoles.EMPLOYEE) ||
            user.roles.includes(userRoles.RESPONSIBLE) ||
            user.roles.includes(userRoles.RESPONSIBLE_ASSIGNER) ||
            user.roles.includes(userRoles.ADMIN))
      );
    },

    statistics({ commit }) {
      return API.getStatisticsForEmployees().then(res => {
        commit("setStatistics", res.data);
      });
    },

    updateEmployeeFilter(_, payload) {
      return API.updateEmployeeFilter(payload.id).then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getOneForEmployeeDetails: () => id => {
      return User.query()
        .where(
          user =>
            user.id === Number(id) &&
            !!user.roles &&
            (user.roles.includes(userRoles.EMPLOYEE) ||
              user.roles.includes(userRoles.RESPONSIBLE) ||
              user.roles.includes(userRoles.RESPONSIBLE_ASSIGNER) ||
              user.roles.includes(userRoles.ADMIN))
        )
        .first();
    },
    getMultipleForEmployeesList() {
      return User.query()
        .where(
          user =>
            !!user.roles &&
            (user.roles.includes(userRoles.EMPLOYEE) ||
              user.roles.includes(userRoles.RESPONSIBLE) ||
              user.roles.includes(userRoles.RESPONSIBLE_ASSIGNER) ||
              user.roles.includes(userRoles.ADMIN))
        )
        .orderBy("id", "desc")
        .get();
    },

    getAlphabetically(state) {
      return Object.values(state.statistics)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.name || 0;
          const valueB = b.name || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getHasMoreEmployees(state) {
      return !!state.hasMoreEmployees;
    },
    getCursorForPagination(state) {
      return state.cursorForPagination;
    },
    getStatistics(_, getters) {
      const employees = getters.getAlphabetically;
      const chartData = {
        labels: [],
        data: {
          ttc: [],
          fee: [],
        },
      };
      employees.forEach(employee => {
        chartData.labels.push(employee.name);
        chartData.data.ttc.push(employee.ttc);
        chartData.data.fee.push(employee.fee);
      });

      return chartData;
    },
  },
};
