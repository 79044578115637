import { Model } from "@vuex-orm/core";
import DossierOfferSet from "./DossierOfferSet";
import Insurer from "./Insurer";

export default class DossierOffer extends Model {
  static get entity() {
    return "dossierOffers";
  }

  static fields() {
    return {
      id: this.attr(null),
      offerData: this.attr(null),
      offerSentDate: this.attr(null),
      dossierOfferSetId: this.attr(null),
      dossierOfferSet: this.belongsTo(DossierOfferSet, "dossierOfferSetId"),
      status: this.attr(null),
      manual: this.attr(false),
      devisFile: this.attr(null),
      offerFiles: this.attr(null),
      pricingFile: this.attr(null),
      insurerId: this.attr(null),
      insurer: this.belongsTo(Insurer, "insurerId"),
      offerSetEmail: this.attr(null),
      contactData: this.attr(null),
      clientActionDate: this.attr(null),
      optionsIncluded: this.attr(null),
      optionsSelected: this.attr(null),
      optionsProposed: this.attr(null),
      productIdentifier: this.attr(null),
      accessHash: this.attr(null),
      prepricingFiles: this.attr(null),
      pretarificationFile: this.attr(null),
      statusSetDate: this.attr(null),
      actionTaken: this.attr(null),
      versions: this.attr(null),
    };
  }
}
