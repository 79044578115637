import UnsubscribedEmail from "../models/UnsubscribedEmail";
import API from "@/api/unsubscribed-emails";

function getDefaultState() {
  return {
    activeSearchTerm: "",

    hasMoreUnsubscribedEmails: null,
    cursorForPagination: null,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, value) {
      state.activeSearchTerm = value;
    },

    setHasMoreUnsubscribedEmails(state, value) {
      state.hasMoreUnsubscribedEmails = value;
    },
    setCursorForPagination(state, value) {
      state.cursorForPagination = value;
    },
    resetPaginationOptions(state) {
      state.hasMoreUnsubscribedEmails = null;
      state.cursorForPagination = null;
    },
  },
  actions: {
    fetchUnsubscribedEmails({ getters, commit }, payload) {
      const cursorForPagination = getters.getCursorForPagination;
      const cursorId = cursorForPagination ? cursorForPagination.id : undefined;

      return API.getUnsubscribedEmails(payload.search, cursorId, payload.itemsPerPage).then(res => {
        const { items, hasMore } = res.data;

        UnsubscribedEmail.insertOrUpdate({
          data: items,
        });

        const lastItemInResponse = items[items.length - 1];
        commit("setCursorForPagination", lastItemInResponse);

        commit("setHasMoreUnsubscribedEmails", hasMore);
      });
    },

    deleteUnsubscribedEmail(_, payload) {
      return API.deleteUnsubscribedEmail(payload.id).then(() => {
        UnsubscribedEmail.delete(payload.id);
      });
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
  },
  getters: {
    getMultipleForUnsubscribedEmailsForList() {
      return UnsubscribedEmail.query().orderBy("id", "desc").get();
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getHasMoreUnsubscribedEmails(state) {
      return !!state.hasMoreUnsubscribedEmails;
    },
    getCursorForPagination(state) {
      return state.cursorForPagination;
    },
  },
};
