const pricingTypes = Object.freeze({
  EXCEL: { key: "excel", name: "Excel" },
  PAPER_QUESTION: { key: "paper_question", name: "Papier + Interrogation" },
  QUESTION: { key: "question", name: "Interrogation" },
});

const INSURERS_ITEMS_PER_PAGE = 15;

const insurerCommunicationItemTypes = Object.freeze({
  DRAFT: { key: "draft", name: "Brouillon" },
  SENT: { key: "sent", name: "Expédié" },
  RESPONSE: { key: "response", name: "Réponse" },
});

export { pricingTypes, INSURERS_ITEMS_PER_PAGE, insurerCommunicationItemTypes };
