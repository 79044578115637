const tarificateur = Object.freeze({
  AMIG: Object.freeze({
    database_key: "amig_axre",
    DO: Object.freeze({
      key: "DO",
      text: "DO CLASSIQUE",
    }),
    DOMI: Object.freeze({
      key: "DOMI",
      text: "DOMI",
    }),
  }),
  MIC: Object.freeze({
    database_key: "mic_leader",
  }),
  ACCELERANT: Object.freeze({
    UBI: Object.freeze({
      database_key: "accelerant_ubi",
    }),
  }),
  CARAVELA: Object.freeze({
    DUNE: Object.freeze({
      database_key: "caravela_dune",
    }),
  }),
  LLOYDS: Object.freeze({
    GESTINEO: Object.freeze({
      database_key: "loyds_gestineo",
    }),
  }),
  SMALL_DO: Object.freeze({
    database_key: "small_do",
  }),
  DUNE: Object.freeze({
    ZURICH: Object.freeze({
      database_key: "dune_zurich",
    }),
  }),

  APRIL: Object.freeze({
    QBE: Object.freeze({
      database_key: "april_qbe",
    }),
  }),
});

export { tarificateur };
