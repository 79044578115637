import User from "../models/User";
import API from "@/api/users";
import employeesAPI from "@/api/employees";
import { userRoles } from "@/constants/users";

function getDefaultState() {
  return {
    clientSpaceInfo: null,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setClientSpaceInfo(state, params) {
      state.clientSpaceInfo = params.value;
    },
  },
  actions: {
    getCurrentUser() {
      return API.getCurrentUser().then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateCurrentUser({ dispatch }, data) {
      return API.updateCurrentUser(data).then(res => {
        User.insertOrUpdate({
          data,
        });
        dispatch("auth/setAuthInfo", { accessToken: res.data.accessToken }, { root: true });
      });
    },

    getAllResponsibles() {
      return employeesAPI.getAllResponsibles().then(res => {
        User.insertOrUpdate({
          data: res.data,
        });
      });
    },

    clientSpaceInfo({ commit }) {
      return API.clientSpaceInfo().then(res => {
        const value = res.data;
        commit("setClientSpaceInfo", { value });
      });
    },

    contactResetPassword(_, payload) {
      return new Promise((resolve, reject) => {
        API.contactResetPassword(payload.data)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getCurrentUser(state) {
      const userId = localStorage.getItem("user-id")
        ? Number(localStorage.getItem("user-id"))
        : null;
      return state.data[userId] ? { ...state.data[userId] } : null;
    },
    get: state => id => {
      return state.data[id] ? { ...state.data[id] } : null;
    },

    getAllResponsibles() {
      return User.query()
        .where(
          user =>
            !!user.roles &&
            (user.roles.includes(userRoles.RESPONSIBLE) ||
              user.roles.includes(userRoles.RESPONSIBLE_ASSIGNER) ||
              user.roles.includes(userRoles.ADMIN))
        )
        .get();
    },

    getClientSpaceInfoText(state) {
      return state.clientSpaceInfo;
    },
  },
};
