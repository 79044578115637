<template>
  <Transition name="fade">
    <button v-if="scY > 900" id="pagetop-btn" class="btn btn-primary-light" @click="toTop">
      <span class="bi bi-chevron-up" />
    </button>
  </Transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
export default {
  setup() {
    let scTimer = ref(null);
    let scY = ref(0);

    function handleScroll() {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = null;
      }, 100);
    }

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    function toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    return { scY, toTop };
  },
};
</script>

<style lang="scss" scoped>
#pagetop-btn {
  position: fixed;
  z-index: 1000;

  bottom: 0.5rem;
  right: 0.5rem;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 0.8rem;

  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  color: $color-white;
  border-radius: 50%;

  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

@include media-breakpoint-up(sm) {
  #pagetop-btn {
    bottom: 0.75rem;
    right: 0.75rem;

    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  #pagetop-btn {
    bottom: 1.25rem;
    right: 1.25rem;

    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.4rem;
  }
}
</style>
