import { userRoles } from "@/constants/users";

function checkIfHasPermittedRole(currentUserRoles, permittedRoles) {
  return currentUserRoles.some(
    role => permittedRoles.includes(role)
    /** ???: role SUPER_ADMIN */
  );
}

function isAdmin(roles) {
  return roles.includes(userRoles.ADMIN);
}
function isResponsible(roles) {
  return roles.includes(userRoles.RESPONSIBLE);
}
function isResponsibleAssigner(roles) {
  return roles.includes(userRoles.RESPONSIBLE_ASSIGNER);
}
function isEmployee(roles) {
  return roles.includes(userRoles.EMPLOYEE);
}
function isAgency(roles) {
  return (
    isAdmin(roles) || isResponsible(roles) || isEmployee(roles) || isResponsibleAssigner(roles)
  );
}

function isPartner(roles) {
  return roles.includes(userRoles.PARTNER);
}
function isClient(roles) {
  return roles.includes(userRoles.CLIENT);
}
function isContact(roles) {
  return isPartner(roles) || isClient(roles);
}

export {
  checkIfHasPermittedRole,
  isAdmin,
  isResponsible,
  isResponsibleAssigner,
  isEmployee,
  isAgency,
  isPartner,
  isClient,
  isContact,
};
