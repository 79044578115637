import axios from "axios";
import links from "@/links";
import qs from "qs";
import { LEADS_ITEMS_PER_PAGE } from "../constants/leads";

export default {
  fetchLeads(pagination = true, page = 1, itemsPerPage = LEADS_ITEMS_PER_PAGE, filters = {}) {
    const { status, partner, leadDateStart, leadDateEnd } = filters;

    let params = {};
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (status) params["status"] = status;

    if (partner) params["partner"] = partner;

    if (leadDateStart || leadDateEnd) {
      params["leadDate"] = {};

      if (leadDateStart) {
        params["leadDate"]["after"] = leadDateStart;
      }
      if (leadDateEnd) {
        params["leadDate"]["before"] = leadDateEnd;
      }
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.LEADS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },

  getLeadsCsv(opts = {}) {
    let { status, partner, leadDate } = opts;
    let params = {
      status,
      partner,
      leadDate,
    };
    return axios.get(`${links.LEADS_EXPORT_CSV_LINK()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },
};
