import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  getCircuits(dossierId, phase) {
    let params = {};
    if (dossierId) params["dossierId"] = dossierId;
    if (phase) params["phase"] = phase;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_ES_CIRCUITS_GET_CIRCUITS() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  createCircuit(phase, dossierId, groupedCheckedFilesWithModels) {
    return axios.post(links.DOSSIER_ES_CIRCUITS_CREATE_CIRCUIT(), {
      phase,
      dossierId,
      groupedCheckedFilesWithModels,
    });
  },
  cancelCircuit(dossierESCircuitId, reasonKey, comment) {
    return axios.post(links.DOSSIER_ES_CIRCUITS_CANCEL_CIRCUIT(), {
      dossierESCircuitId,

      reasonKey,
      comment,
    });
  },
  syncCircuit(dossierESCircuitId) {
    return axios.post(links.DOSSIER_ES_CIRCUITS_SYNC_CIRCUIT(), {
      dossierESCircuitId,
    });
  },

  deleteDossierESCircuit(id) {
    return axios.delete(links.DOSSIER_ES_CIRCUIT_LINK(id));
  },
};
