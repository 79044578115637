import { Model } from "@vuex-orm/core";

export default class Lead extends Model {
  static get entity() {
    return "leads";
  }

  static fields() {
    return {
      id: this.attr(null),
      status: this.attr(null),
      statusInfo: this.attr(null),
      partner: this.attr(null),
      contactEmail: this.attr(null),
      buildingZipcode: this.attr(null),
      leadDate: this.attr(null),
      dossierData: this.attr(null),
    };
  }
}
