import DossierCompanyBuilding from "../models/DossierCompanyBuilding";
import API from "@/api/dossierCompaniesBuilding";
import { getLotPosition } from "@/hooks/dossierCompany";

function getDefaultState() {
  return {
    countLots: 20,
  };
}
function getPosition(data) {
  let position = data.position || 0;
  if (data.position === 0) {
    if (data.rowData.devises.length > 0) {
      if (data.rowData.devises[0].lots && data.rowData.devises[0].lots.length > 0) {
        position = getLotPosition(data.rowData.devises[0].lots[0]);
      }
      data.rowData.devises.forEach(devis => {
        if (devis.lots) {
          devis.lots.forEach(lot => {
            const lotPosition = getLotPosition(lot);
            if (position > lotPosition) {
              position = lotPosition;
            }
          });
        }
      });
    }
  }

  return position;
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        res.data.forEach(item => {
          const displayPosition = getPosition(item);
          item.displayPosition = displayPosition;
          DossierCompanyBuilding.insertOrUpdate({
            data: item,
          });
        });
      });
    },
    createAPI({ state }, payload) {
      return API.create(payload).then(res => {
        const length = DossierCompanyBuilding.query().get().length;
        res.data.displayPosition = length + state.countLots;

        DossierCompanyBuilding.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data, payload.opts).then(res => {
        DossierCompanyBuilding.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id, payload.opts).then(() => {
        DossierCompanyBuilding.delete(payload.id);
      });
    },
    updateSyncData(_, payload) {
      return API.updateSyncData(payload.id, payload.data).then(res => {
        DossierCompanyBuilding.insertOrUpdate({
          data: res.data,
        });
      });
    },

    createByClientAPI({ state }, payload) {
      return new Promise((resolve, reject) => {
        API.createByClientAPI(payload)
          .then(res => {
            const length = DossierCompanyBuilding.query().get().length;
            res.data.displayPosition = length + state.countLots;

            DossierCompanyBuilding.insertOrUpdate({
              data: res.data,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.displayPosition || 0;
          const valueB = b.displayPosition || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
  },
};
