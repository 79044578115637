import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

export default function useBreakpoints() {
  const store = useStore();
  function setCurrentWindowWidth(width) {
    store.dispatch("breakpoints/setCurrentWindowWidth", width);
  }
  function setCurrentBreakpoint(width) {
    store.dispatch("breakpoints/setCurrentBreakpoint", width);
  }

  setCurrentWindowWidth(window.innerWidth);
  setCurrentBreakpoint(window.innerWidth);

  function onWidthChange() {
    setCurrentWindowWidth(window.innerWidth);
    setCurrentBreakpoint(window.innerWidth);
  }

  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));
}
