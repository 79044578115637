import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  sendMessage(targetedInstanceModel, targetedInstanceId, content, uid, opts = {}) {
    const { isPhase7 } = opts;

    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      isPhase7,
    };
    return axios.post(`${links.MESSAGING_SEND_MESSAGE()}?${qs.stringify(params)}`, {
      content,
      uid,
    });
  },

  create(targetedInstanceModel, targetedInstanceId, content, opts = {}) {
    const { isPhase7 } = opts;

    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      isPhase7,
    };
    return axios.post(`${links.MESSAGING_CREATE()}?${qs.stringify(params)}`, {
      content,
    });
  },

  update(targetedInstanceModel, targetedInstanceId, content, uid, opts = {}) {
    const { isPhase7 } = opts;

    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      isPhase7,
    };

    return axios.put(`${links.MESSAGING_UPDATE()}?${qs.stringify(params)}`, {
      content,
      uid,
    });
  },

  remove(targetedInstanceModel, targetedInstanceId, uid, opts = {}) {
    const { isPhase7 } = opts;

    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      isPhase7,
    };
    return axios.post(`${links.MESSAGING_DELETE_COMMUNICATION_DATA()}?${qs.stringify(params)}`, {
      uid,
    });
  },
};
