import API from "@/api/devisCommunicationFiles";
import DevisCommunication from "../database/models/DevisCommunication";

export default {
  namespaced: true,
  actions: {
    uploadFile(_, payload) {
      const { devisCommunicationId, formData } = payload;
      return API.uploadFile(devisCommunicationId, formData).then(res => {
        DevisCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteFile(_, payload) {
      const { devisCommunicationId, filename } = payload;
      return API.deleteFile(devisCommunicationId, filename).then(res => {
        DevisCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
};
