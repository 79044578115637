import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  update(id, data, opts = {}) {
    const { isNew, isEdit, isDelete } = opts;
    const params = {
      isNew,
      isEdit,
      isDelete,
    };
    const hasParamsSet = Object.keys(params).length > 0;

    return axios.put(
      links.DOSSIER_OFFERS_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      data
    );
  },
  create(data) {
    return axios.post(links.DOSSIER_OFFER_LINK(), data);
  },

  uploadOfferFile(dossierOfferId, dossierId, formData) {
    const params = {
      dossierId,
    };
    return axios.post(
      `${links.DOSSIER_OFFER_LINK_OFFER_FILE(dossierOfferId)}?${qs.stringify(params)}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  deleteOfferFile(dossierOfferId, filename) {
    const params = { filename };
    return axios.delete(
      `${links.DOSSIER_OFFER_LINK_OFFER_FILE(dossierOfferId)}?${qs.stringify(params)}`
    );
  },

  uploadPrepricingFile(id, dossierId, formData) {
    const params = {
      dossierId,
    };
    return axios.post(
      `${links.DOSSIER_OFFER_LINK_UPLOAD_PREPRICING_FILE(id)}?${qs.stringify(params)}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  deletePrepricingFile(id, data) {
    return axios.put(links.DOSSIER_OFFER_LINK_DELETE_PREPRICING_FILE(id), data);
  },

  getDossierOffer(id) {
    return axios.get(links.DOSSIER_OFFERS_LINK(id));
  },
  getRequestedDossierOffers(dossierId) {
    return axios.get(links.DOSSIER_OFFERS_REQUESTED_LINK(dossierId));
  },
  acceptAsAgency(dossierOfferId, totalRequired, currentRequired) {
    const data = {
      totalRequired: totalRequired,
      currentRequired: currentRequired,
    };
    return axios.post(links.DOSSIER_OFFER_ACCEPT_AGENCY_LINK(dossierOfferId), data);
  },
  delete(id) {
    return axios.delete(links.DOSSIER_OFFERS_LINK(id));
  },
  initOffer(id) {
    return axios.put(links.DOSSIER_OFFERS_INIT_LINK(id));
  },
  sendFilesOnDemand(dossierOfferId) {
    return axios.post(links.DOSSIER_OFFER_SEND_FILES_ON_DEMAND(dossierOfferId));
  },
  sendOffer(dossierOfferId) {
    return axios.post(links.DOSSIER_OFFER_SEND_OFFER(dossierOfferId));
  },
  createVersion(dossierOfferId) {
    return axios.post(links.DOSSIER_OFFER_CREATE_VERSION(dossierOfferId));
  },
  cancelVersion(dossierOfferId, versionUid) {
    return axios.post(links.DOSSIER_OFFER_CANCEL_VERSION(dossierOfferId, versionUid));
  },
  saveOffer(dossierOfferId) {
    return axios.post(links.DOSSIER_OFFER_SAVE(dossierOfferId));
  },
};
