import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  uploadFile(insurerCommunicationId, formData) {
    const params = {
      insurerCommunicationId,
    };
    return axios.post(
      `${links.INSURER_COMMUNICATION_FILES_LINK()}?${qs.stringify(params)}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  deleteFile(insurerCommunicationId, filename) {
    const params = {
      insurerCommunicationId,
      filename,
    };
    return axios.delete(`${links.INSURER_COMMUNICATION_FILES_LINK()}?${qs.stringify(params)}`);
  },
  getFilePublic(signatureToVerify, dossierAccessHash, filename) {
    const params = {
      file: filename,
      sign: signatureToVerify,
    };
    window.open(
      `${links.INSURER_COMMUNICATION_FILES_PUBLIC_LINK(dossierAccessHash)}?${qs.stringify(params)}`
    );
  },
};
