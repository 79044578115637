import DossierContract from "../models/DossierContract";
import API from "@/api/dossierContracts";
import { phasesDocs } from "@/constants/dossier.structure";
import { modelNames } from "@/constants/models";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        DossierContract.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierContract.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(res => {
        DossierContract.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierContract.delete(payload.id);
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.position || 0;
          const valueB = b.position || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },

    getAllActiveWithFilesForEdiSignature(_, getters) {
      return getters.getAllActive
        .filter(item => item.rowData.files.length > 0)
        .map(item => ({ ...item, model: modelNames.DOSSIER_CONTRACT }));
    },
    getDossierContractsAttPrevAndContract() {
      const contract = DossierContract.query()
        .where(item => {
          return item.rowData.document === phasesDocs.CONTRACT_SIGNED.key;
        })
        .first();

      const attPrev = DossierContract.query()
        .where(item => {
          return item.rowData.document === phasesDocs.CERTIFICATE_PROVISIONAL.key;
        })
        .first();

      return {
        contract,
        attPrev,
      };
    },
  },
};
