import BackupLogging from "../models/BackupLogging";
import API from "@/api/backupLogging";
import { BACKUP_ITEMS_PER_PAGE } from "@/constants/backup";

function getDefaultState() {
  return {
    paginationOptions: {},
    itemsPerPage: BACKUP_ITEMS_PER_PAGE,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    listCallPerformed(state, { page, totalItems }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
      });
    },
    resetPaginationOptions(state) {
      Object.keys(state.paginationOptions).forEach(key => {
        delete state.paginationOptions[key];
      });
    },
  },
  actions: {
    getBackupLogging({ commit, state }, payload) {
      return API.getBackupLogging(
        payload.pagination,
        payload.page,
        state.itemsPerPage,
        payload.sortBy,
        payload.sortOrder
      ).then(res => {
        BackupLogging.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
        });
      });
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
  },
  getters: {
    getMultipleForBackupList:
      state =>
      (sortBy = "crtDate", sortOrder = "desc") => {
        return Object.values(state.data)
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(dossier => ({ ...dossier }));
      },

    getPage: state => {
      return state.paginationOptions.page;
    },
    getTotalItems: state => {
      return state.paginationOptions.totalItems;
    },
    getCurrentItemsInStore: state => {
      return Object.values(state.data).length;
    },
  },
};
