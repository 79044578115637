import axios from "axios";
import links from "@/links";
import qs from "qs";
import { EMPLOYEES_ITEMS_PER_PAGE } from "../constants/employees";

export default {
  getAllResponsibles() {
    return axios.get(links.EMPLOYEES_ALL_RESPONSIBLES());
  },
  getEmployees(search, cursorId, onlyResponsibles = 0, itemsPerPage = false) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (cursorId) {
      params["cursorId"] = cursorId;
    }
    if (onlyResponsibles) {
      params["onlyResponsibles"] = onlyResponsibles;
    }

    params["itemsPerPage"] = EMPLOYEES_ITEMS_PER_PAGE;
    if (itemsPerPage) {
      params["itemsPerPage"] = itemsPerPage;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.EMPLOYEES_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  getEmployee(id) {
    return axios.get(links.EMPLOYEE_LINK(id));
  },

  createEmployee(data) {
    return axios.post(links.EMPLOYEES_LINK(), data);
  },
  updateEmployee(data) {
    return axios.put(links.EMPLOYEE_LINK(data.id), data);
  },

  resendInvitation(id) {
    return axios.put(links.EMPLOYEE_RESEND_INVITATION(id));
  },
  resetPasswordReq(id) {
    return axios.put(links.EMPLOYEE_RESET_PASSWORD_REQ(id));
  },
  deactivateAccount(id) {
    return axios.put(links.EMPLOYEE_DEACTIVATE_ACCOUNT(id));
  },
  deleteAccount(id) {
    return axios.delete(links.EMPLOYEE_LINK(id));
  },
  activateAccount(id) {
    return axios.put(links.EMPLOYEE_ACTIVATE_ACCOUNT(id));
  },

  getStatisticsForEmployees() {
    return axios.get(links.EMPLOYEE_STATISTICS_LINK());
  },
  updateEmployeeFilter(id) {
    return axios.put(links.EMPLOYEE_UPDATE_FILTER(id));
  },
};
