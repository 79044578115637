import { createStore, createLogger } from "vuex";

import VuexORM from "@vuex-orm/core";
import { database } from "./database";

import general from "./modules/general";
import auth from "./modules/auth";
import breakpoints from "./modules/breakpoints";
import dossierFiles from "./modules/dossierFiles";
import insurerCommunicationZipFiles from "./modules/insurerCommunicationZipFiles";
import insurerCommunicationFiles from "./modules/insurerCommunicationFiles";
import dossierOfferInsurerCommunicationFiles from "./modules/dossierOfferInsurerCommunicationFiles";
import dossierDetails from "./modules/dossierDetails";
import messaging from "./modules/messaging";
import employees from "./modules/employees";
import partners from "./modules/partners";
import generalSetting from "./modules/generalSetting";
import devisCommunicationFiles from "./modules/devisCommunicationFiles";

const isInProduction = import.meta.env.PROD;

// Create a new store instance.
export default createStore({
  modules: {
    general,
    auth,
    breakpoints,
    dossierFiles,
    insurerCommunicationZipFiles,
    insurerCommunicationFiles,
    dossierOfferInsurerCommunicationFiles,
    dossierDetails,
    messaging,
    employees,
    partners,
    generalSetting,
    devisCommunicationFiles,
  },
  strict: !isInProduction,
  plugins: isInProduction
    ? [VuexORM.install(database)]
    : [createLogger(), VuexORM.install(database)],
});
