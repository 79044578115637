import DossierPreInsurancePayment from "../models/DossierPreInsurancePayment";
import API from "@/api/dossierPreInsurancePayments";
import { phasesDocs } from "@/constants/dossier.structure";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        DossierPreInsurancePayment.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierPreInsurancePayment.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(res => {
        DossierPreInsurancePayment.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierPreInsurancePayment.delete(payload.id);
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.position || 0;
          const valueB = b.position || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
    getFinalCountDocument() {
      const finalCount = DossierPreInsurancePayment.query()
        .where(item => {
          return item.rowData.document === phasesDocs.FINAL_COUNT.key;
        })
        .first();

      return finalCount;
    },
  },
};
