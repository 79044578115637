import { Model } from "@vuex-orm/core";

export default class UserActionLog extends Model {
  static get entity() {
    return "userActionLogs";
  }

  static fields() {
    return {
      id: this.attr(null),
      dossierId: this.attr(null),
      userId: this.attr(null),
      user: this.attr(null),
      action: this.attr(null),
      createdAt: this.attr(null),
    };
  }
}
