import API from "@/api/generalSetting";

export default {
  namespaced: true,
  state() {
    return {
      spaceClientInfo: {},
      subscriptionFile: [],
      receptionFile: [],
      authorizeOneDrive: [],
      oneDriveData: [],
    };
  },
  mutations: {
    setSpaceClientInfo(state, value) {
      state.spaceClientInfo = value;
    },
    setSubscriptionFile(state, value) {
      state.subscriptionFile = value;
    },
    setReceptionFile(state, value) {
      state.receptionFile = value;
    },
    setAuthorizeOneDrive(state, value) {
      state.authorizeOneDrive = value;
    },
    setDriveAndItemId(state, value) {
      state.oneDriveData = value;
    },
  },
  actions: {
    fetchSpaceClientInfo({ commit }) {
      return API.getSpaceClientInfo().then(res => {
        commit("setSpaceClientInfo", res.data);
      });
    },

    updateSpaceClientInfo({ commit }, payload) {
      return API.updateSpaceClientInfo(payload).then(res => {
        commit("setSpaceClientInfo", res.data);
      });
    },
    updateGeneralSettingFile({ commit }, payload) {
      return API.uploadGeneralSettingFile(payload.formData).then(res => {
        if (payload.type === "subscription") {
          commit("setSubscriptionFile", res.data);
        } else {
          commit("setReceptionFile", res.data);
        }
      });
    },
    fetchSubscriptionFile({ commit }) {
      return API.getSubscriptionFile().then(res => {
        commit("setSubscriptionFile", res.data);
      });
    },
    fetchReceptionFile({ commit }) {
      return API.getReceptionFile().then(res => {
        commit("setReceptionFile", res.data);
      });
    },
    deleteGeneralSettingFile({ commit }, payload) {
      return API.deleteGeneralSettingFile(payload).then(res => {
        if (payload.type === "subscription") {
          commit("setSubscriptionFile", res.data);
        } else {
          commit("setReceptionFile", res.data);
        }
      });
    },
    authorizeOneDrive({ commit }, payload) {
      return API.authorizeOneDrive(payload).then(res => {
        commit("setAuthorizeOneDrive", res.data);
      });
    },
    fetchOneDriveAndItemId({ commit }) {
      return API.getOneDriveItemAndDriveId().then(res => {
        commit("setDriveAndItemId", res.data);
      });
    },
    updateOneDriveInfo({ commit }, payload) {
      return API.updateOneDriveInfo(payload).then(res => {
        commit("setDriveAndItemId", res.data);
      });
    },
    getOneDriveIdentifiers({ commit }, payload) {
      return API.getOneDriveIdentifiers(payload).then(res => {
        commit("setDriveAndItemId", res.data);
      });
    },
  },
  getters: {
    getSpaceClientInfo(state) {
      return state.spaceClientInfo;
    },
    getSubscriptionFile(state) {
      return state.subscriptionFile;
    },
    getReceptionFile(state) {
      return state.receptionFile;
    },
    getDriveAndItemId(state) {
      return state.oneDriveData;
    },
  },
};
