import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(pagination = true, page = 1, itemsPerPage = 10) {
    let params = {};
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_ARCHIVED_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
};
