import DossierOfferSet from "../models/DossierOfferSet";
import API from "@/api/dossierOfferSets";
import DossierOffer from "../models/DossierOffer";
import Dossier from "../models/Dossier";
import Insurer from "../models/Insurer";
import moment from "moment";
import { phasesNames } from "@/constants/dossier.structure";

function getDefaultState() {
  return {
    compareData: {},
    subscriptionData: {},
  };
}

function sort(toReturn, phase) {
  toReturn = toReturn.sort((a, b) => {
    let dateA;
    let dateB;
    if (phase == phasesNames.PHASE_B.key) {
      dateA = a.sentDate;
      dateB = b.sentDate;
    } else {
      dateA = a.clientActionDate ? a.clientActionDate : a.sentDate;
      dateB = b.clientActionDate ? b.clientActionDate : b.sentDate;
    }

    const momentA = moment(dateA).unix();
    const momentB = moment(dateB).unix();
    if (momentA > momentB) {
      return -1;
    } else {
      return momentA < momentB ? 1 : 0;
    }
  });
  return toReturn;
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },
    setCompareOffers(state, value) {
      state.compareData = value;
    },
    setSubscriptionData(state, value) {
      state.subscriptionData = value;
    },
  },
  actions: {
    getAllByDossier(_, payload) {
      return API.getDossierOfferSets(payload).then(res => {
        Insurer.insertOrUpdate({
          data: res.data.insurers,
        });
        DossierOfferSet.insertOrUpdate({
          data: res.data.dossierOfferSets,
        });
      });
    },
    duplicate(_, payload) {
      return API.duplicate(payload.dossierOfferSetId).then(res => {
        DossierOfferSet.insertOrUpdate({
          data: res.data,
        });
        for (let dossierOffer of res.data.dossierOffers) {
          dossierOffer["dossierOfferSetId"] = res.data.id;
          DossierOffer.insertOrUpdate({ data: dossierOffer });
          if (dossierOffer.insurer) {
            Insurer.insertOrUpdate({ data: dossierOffer.insurer });
          }
        }
      });
    },
    sendPricing(_, payload) {
      return API.sendPricing(payload.id, payload.sendAllOffers).then(res => {
        DossierOfferSet.insertOrUpdate({
          data: res.data,
        });
      });
    },
    savePricing(_, payload) {
      return API.savePricing(payload).then(res => {
        DossierOfferSet.insertOrUpdate({
          data: res.data,
        });
      });
    },
    cancelEdit(_, payload) {
      return API.cancelEdit(payload).then(() => {
        DossierOfferSet.delete(payload);
      });
    },
    getByDossierOfferSetAccessHash(_, payload) {
      return API.getByDossierOfferSetAccessHash(payload).then(res => {
        DossierOfferSet.insertOrUpdate({
          data: res.data.dossierOfferSet,
        });
        Dossier.insertOrUpdate({
          data: res.data.dossier,
        });
        Insurer.insertOrUpdate({
          data: res.data.insurers,
        });
      });
    },
    compareOffers({ commit }, params) {
      return API.compareOffers(params).then(res => {
        commit("setCompareOffers", {
          compareOffers: res.data.compareOffers,
        });
      });
    },
    subscriptionData({ commit }, params) {
      return API.subscriptionData(params).then(res => {
        commit("setSubscriptionData", res.data);
      });
    },
  },
  getters: {
    get: () => id => {
      return DossierOfferSet.query().with("dossierOffers").find(id);
    },
    getByAccessHash: () => accessHash => {
      return DossierOfferSet.query().where("accessHash", accessHash).with("dossierOffers").first();
    },
    getAll: () => phase => {
      const toReturn = DossierOfferSet.query().with("dossierOffers").get();
      return sort(toReturn, phase);
    },
    getNotSentByStatus: () => status => {
      return DossierOfferSet.query().where("status", status).get().length;
    },
    getCompareOffersData(state) {
      return state.compareData.compareOffers;
    },
    getSubscriptionData(state) {
      return state.subscriptionData;
    },
  },
};
