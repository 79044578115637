import { Model } from "@vuex-orm/core";

export default class InsurerCommunication extends Model {
  static get entity() {
    return "insurerCommunications";
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      phase: this.attr(null),
      file: this.attr(null),
      files: this.attr(null),
      content: this.attr(null),
      sentDate: this.attr(null),
      sentTo: this.attr(null),
      dossierId: this.attr(null),
      countFiles: this.attr(null),
    };
  }
}
