import API from "@/api/auth";
import VueJwtDecode from "vue-jwt-decode";
import { userRoles } from "@/constants/users";
import { sentrySetUser, sentryRemoveUser } from "@/configs/sentry.config";

export default {
  namespaced: true,
  state() {
    return {
      accessToken: localStorage.getItem("access-token") || null,
      userId: localStorage.getItem("user-id") ? Number(localStorage.getItem("user-id")) : null,
      userEmail: localStorage.getItem("user-email") || null,
    };
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserEmail(state, userEmail) {
      state.userEmail = userEmail;
    },
  },
  actions: {
    setAuthInfo({ commit }, payload) {
      const { accessToken, refreshToken } = payload;

      commit("setAccessToken", accessToken);
      localStorage.setItem("access-token", accessToken);
      if (refreshToken) localStorage.setItem("refresh-token", refreshToken);

      const decodedToken = VueJwtDecode.decode(accessToken);

      commit("setUserId", decodedToken.userId);
      localStorage.setItem("user-id", decodedToken.userId);

      commit("setUserEmail", decodedToken.userEmail);
      localStorage.setItem("user-email", decodedToken.userEmail);

      sentrySetUser({
        id: decodedToken.userId,
        email: decodedToken.userEmail,
        roles: decodedToken.roles,
      });
    },
    removeAuthInfo() {
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");

      localStorage.removeItem("user-id");
      localStorage.removeItem("user-email");

      sentryRemoveUser();

      // !!!: ..
      location.reload();
    },

    authenticate({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        API.requestTokens(payload)
          .then(res => {
            dispatch("setAuthInfo", {
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deauthenticate({ dispatch }) {
      dispatch("removeAuthInfo");
    },

    refreshToken({ dispatch }) {
      return new Promise((resolve, reject) => {
        API.refreshToken({
          refreshToken: localStorage.getItem("refresh-token"),
        })
          .then(res => {
            dispatch("setAuthInfo", {
              accessToken: res.data.accessToken,
            });
            resolve(res);
          })
          .catch(err => {
            dispatch("deauthenticate");
            reject(err);
          });
      });
    },
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    isAuthenticated(state) {
      return !!state.accessToken;
    },

    userId(state) {
      return state.userId;
    },
    userEmail(state) {
      return state.userEmail;
    },

    currentUserRoles(state) {
      try {
        const decodedToken = VueJwtDecode.decode(state.accessToken);
        return decodedToken.roles;
      } catch {
        return [];
      }
    },
    isAdmin(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.ADMIN);
      } catch {
        return false;
      }
    },
    isEmployee(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.EMPLOYEE);
      } catch {
        return false;
      }
    },
    isResponsible(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.RESPONSIBLE);
      } catch {
        return false;
      }
    },
    isResponsibleAssigner(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.RESPONSIBLE_ASSIGNER);
      } catch {
        return false;
      }
    },

    isPartner(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.PARTNER);
      } catch {
        return false;
      }
    },
    isClient(_, getters) {
      try {
        return getters.currentUserRoles.includes(userRoles.CLIENT);
      } catch {
        return false;
      }
    },

    isAgency(_, getters) {
      return (
        getters.isAdmin ||
        getters.isEmployee ||
        getters.isResponsible ||
        getters.isResponsibleAssigner
      );
    },
    isContact(_, getters) {
      return getters.isPartner || getters.isClient;
    },
  },
};
