import axios from "axios";
import links from "@/links";
import qs from "qs";
import { INSURERS_ITEMS_PER_PAGE } from "../constants/insurers";

export default {
  getCompanies(search, gdpr, pagination = true, page = 1, itemsPerPage = INSURERS_ITEMS_PER_PAGE) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (gdpr) {
      params["gdpr"] = gdpr;
    }
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.COMPANIES_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  update(id, data) {
    return axios.put(links.COMPANY_LINK(id), data);
  },
};
