export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    isEmployee() {
      return this.$store.getters["auth/isEmployee"];
    },
    isResponsible() {
      return this.$store.getters["auth/isResponsible"];
    },
    isResponsibleAssigner() {
      return this.$store.getters["auth/isResponsibleAssigner"];
    },
    isPartner() {
      return this.$store.getters["auth/isPartner"];
    },
    isClient() {
      return this.$store.getters["auth/isClient"];
    },

    isAgency() {
      return this.$store.getters["auth/isAgency"];
    },
    isContact() {
      return this.$store.getters["auth/isContact"];
    },
  },
};
