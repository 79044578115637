import DossierAdministrativeDocument from "../models/DossierAdministrativeDocument";
import API from "@/api/dossierAdministrativeDocuments";
import { phasesDocs } from "@/constants/dossier.structure";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierAdministrativeDocument.delete(payload.id);
      });
    },

    fetchQuestionnaire(_, payload) {
      return API.fetchQuestionnaire(payload.accessHash).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateQuestionnaireData(_, payload) {
      return API.updateQuestionnaireData(payload.id, payload.data).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    saveQuestionnaire(_, payload) {
      return API.saveQuestionnaire(payload.id, payload.data).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
    sendDocumentReceipt(_, payload) {
      return new Promise((resolve, reject) => {
        API.sendDocumentReceipt(payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    generateReceptionPdf(_, payload) {
      return API.generateReceptionPdf(payload.id).then(res => {
        DossierAdministrativeDocument.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.position || 0;
          const valueB = b.position || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
    getDGDQuestionnaire() {
      return DossierAdministrativeDocument.query()
        .where(item => {
          return item.rowData.document === phasesDocs.DGD.key;
        })
        .first();
    },
  },
};
