import API from "@/api/dossierFiles";
import * as db from "../database";

async function insertOrUpdateTargetedInstance(modelName, data) {
  const model = db[modelName];
  model.insertOrUpdate({
    data,
  });
}

export default {
  namespaced: true,
  actions: {
    uploadFile(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, formData, opts } = payload;
      return API.uploadFile(targetedInstanceModel, targetedInstanceId, formData, opts).then(res => {
        insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
      });
    },
    deleteFile(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, filename, opts } = payload;
      return API.deleteFile(targetedInstanceModel, targetedInstanceId, filename, opts).then(res => {
        insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
      });
    },
    mergeFilesInOnePdf(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, opts } = payload;
      return API.mergeFilesInOnePdf(targetedInstanceModel, targetedInstanceId, opts).then(res => {
        insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
      });
    },
    deleteCommunicationFile(_, payload) {
      const { targetedInstanceModel, targetedInstanceId, filename } = payload;
      return API.deleteCommunicationFile(targetedInstanceModel, targetedInstanceId, filename).then(
        res => {
          insertOrUpdateTargetedInstance(targetedInstanceModel, res.data);
        }
      );
    },
  },
};
