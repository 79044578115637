import { Model } from "@vuex-orm/core";

export default class UnsubscribedEmail extends Model {
  static get entity() {
    return "unsubscribedEmails";
  }

  static fields() {
    return {
      id: this.attr(null),
      email: this.attr(null),
      unsubscribeDate: this.attr(null),
      type: this.attr(null),
      typeName: this.attr(null),
    };
  }
}
