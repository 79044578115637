import axios from "axios";
import links from "@/links";
import { UNSUBSCRIBED_EMAILS_ITEMS_PER_PAGE } from "../constants/unsubscribed-emails";

export default {
  getUnsubscribedEmails(search, cursorId, itemsPerPage = false) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (cursorId) {
      params["cursorId"] = cursorId;
    }

    params["itemsPerPage"] = UNSUBSCRIBED_EMAILS_ITEMS_PER_PAGE;
    if (itemsPerPage) {
      params["itemsPerPage"] = itemsPerPage;
    }

    return axios.get(links.UNSUBSCRIBED_EMAILS_LINK, { params });
  },
  deleteUnsubscribedEmail(id) {
    return axios.delete(links.UNSUBSCRIBED_EMAIL_LINK(id));
  },
};
