import { Model } from "@vuex-orm/core";

export default class DossierExport extends Model {
  static get entity() {
    return "dossierExport";
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(null),
      exportFile: this.attr(null),
      zipFile: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      insurer: this.attr(null),
      agencyCompany: this.attr(null),
      period: this.attr(null),
      statusOneDrive: this.attr(null),
      errorLogOneDrive: this.attr(null),
      statusFilesOneDrive: this.attr(null),
    };
  }
}
