import UserActionLog from "../models/UserActionLog";
import API from "@/api/userActionLogs";

function getDefaultState() {
  return {};
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    getByDossierAndAction(_, payload) {
      return API.getByDossierAndAction(payload.dossierId, payload.action).then(res => {
        UserActionLog.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    getByDossierAndAction: state => (dossierId, action) => {
      return Object.values(state.data).find(
        log => log.dossierId === dossierId && log.action === action
      );
    },
  },
};
