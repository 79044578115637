import { Model } from "@vuex-orm/core";
import Dossier from "./Dossier";

export default class DossierInsurance extends Model {
  static get entity() {
    return "dossierInsurances";
  }

  static fields() {
    return {
      id: this.attr(null),
      rowData: this.attr(null),
      dossierId: this.attr(null),
      dossier: this.belongsTo(Dossier, "dossierId"),
      locked: this.attr(false),
      active: this.attr(false),
      communicationData: this.attr(null),
      status: this.attr(null),
      invalidStatusReason: this.attr(null),
      invalidStatusComment: this.attr(null),
      customAdded: this.attr(false),
      position: this.attr(false),
    };
  }
}
