import { Model } from "@vuex-orm/core";

export default class User extends Model {
  static get entity() {
    return "users";
  }

  static fields() {
    return {
      id: this.attr(null),
      email: this.attr(null),
      firstname: this.attr(null),
      lastname: this.attr(null),
      phone: this.attr(null),
      password: this.attr(null),
      resetPasswordCode: this.attr(null),
      resetPasswordAt: this.attr(null),
      activationCode: this.attr(null),
      activationAt: this.attr(null),
      activatedAt: this.attr(null),
      roles: this.attr(null),
      partnerKey: this.attr(null),
      partnerFee: this.attr(null),
      communicationDestination: this.attr(null),
      statistics: this.attr(null),
      agreementSigned: this.attr(null),
      socialReason: this.attr(null),
      contributorNumber: this.attr(null),
      employeeFilter: this.attr(null),
      countDossiers: this.attr(null),
      countDossiersReception: this.attr(null),
    };
  }
}
