import { Model } from "@vuex-orm/core";

export default class DossierESCircuit extends Model {
  static get entity() {
    return "dossierESCircuits";
  }

  static fields() {
    return {
      id: this.attr(null),
      dossierId: this.attr(null),
      agencyCompany: this.attr(null),
      reference: this.attr(null),
      phase: this.attr(null),
      rowsData: this.attr(null),
      status: this.attr(null),
      history: this.attr(null),

      // HACK: ..
      ediSignatureData: this.attr(null),
    };
  }
}
