import { Model } from "@vuex-orm/core";
import Dossier from "./Dossier";

export default class DossierQuestionnaire extends Model {
  static get entity() {
    return "dossierQuestionnaires";
  }

  static fields() {
    return {
      id: this.attr(null),
      rowData: this.attr(null),
      dossierId: this.attr(null),
      dossier: this.belongsTo(Dossier, "dossierId"),
      updatedAt: this.attr(null),
      submittedAt: this.attr(null),
      status: this.attr(null),
      accessHash: this.attr(false),
      progress: this.attr(false),
      insurerLogoFilename: this.attr(null),
      insurerIdentifier: this.attr(null),
      insurerPromoted: this.attr(null),
      clientUrl: this.attr(null),
      beneficiarsOutsideEU: this.attr(null),
    };
  }
}
