import { breakpoints } from "@/constants/breakpoints";

export default {
  namespaced: true,
  state() {
    return {
      currentWindowWidth: 0,
      currentBreakpoint: "",
    };
  },
  mutations: {
    setCurrentWindowWidth(state, width) {
      state.currentWindowWidth = width;
    },
    setCurrentBreakpoint(state, width) {
      let breakpoint = "xxs";

      if (width >= breakpoints.xxl) breakpoint = "xxl";
      else if (width >= breakpoints.xl) breakpoint = "xl";
      else if (width >= breakpoints.lg) breakpoint = "lg";
      else if (width >= breakpoints.md) breakpoint = "md";
      else if (width >= breakpoints.sm) breakpoint = "sm";
      else if (width >= breakpoints.xs) breakpoint = "xs";

      state.currentBreakpoint = breakpoint;
    },
  },
  actions: {
    setCurrentWindowWidth({ commit }, width) {
      commit("setCurrentWindowWidth", width);
    },
    setCurrentBreakpoint({ commit }, width) {
      commit("setCurrentBreakpoint", width);
    },
  },
  getters: {
    getCurrentWindowWidth(state) {
      return state.currentWindowWidth;
    },
    getCurrentBreakpoint(state) {
      return state.currentBreakpoint;
    },

    screenGteSm(state) {
      return state.currentWindowWidth >= breakpoints.sm;
    },
    screenGteMd(state) {
      return state.currentWindowWidth >= breakpoints.md;
    },
    screenGteLg(state) {
      return state.currentWindowWidth >= breakpoints.lg;
    },
    screenGteXl(state) {
      return state.currentWindowWidth >= breakpoints.xl;
    },
  },
};
