import { Model } from "@vuex-orm/core";

export default class DossierTrashedDocument extends Model {
  static get entity() {
    return "dossierTrashedDocuments";
  }

  static fields() {
    return {
      id: this.attr(null),
      dossierId: this.attr(null),
      file: this.attr(null),
      extraData: this.attr(null),
      timestamp: this.attr(null),
    };
  }
}
