export default {
  namespaced: true,
  state() {
    return {
      showLoaderOverlay: false,
      loaderOverlayText: "",
      offerEditMode: false,
      dossierOfferExpandedId: null,
      compareMode: true,
      editOneOfferMode: false,
    };
  },
  mutations: {
    showLoaderOverlay(state) {
      state.showLoaderOverlay = true;
    },
    hideLoaderOverlay(state) {
      state.showLoaderOverlay = false;
    },
    setLoaderOverlayText(state, value) {
      state.loaderOverlayText = value || "";
    },
    enterOfferEditMode(state) {
      state.offerEditMode = true;
    },
    exitOfferEditMode(state) {
      state.offerEditMode = false;
    },
    setDossierOfferExpandedId(state, value) {
      state.dossierOfferExpandedId = value || null;
    },
    setCompareMode(state, value) {
      state.compareMode = value;
    },
  },
  actions: {
    showLoaderOverlay({ commit }, payload = {}) {
      commit("showLoaderOverlay");
      commit("setLoaderOverlayText", payload.text ? payload.text : "S'il vous plaît, attendez.");
    },
    hideLoaderOverlay({ commit }) {
      commit("hideLoaderOverlay");
      commit("setLoaderOverlayText", "");
    },
    enterOfferEditMode({ commit }) {
      commit("enterOfferEditMode");
    },
    exitOfferEditMode({ commit }) {
      commit("exitOfferEditMode");
    },
    setDossierOfferExpandedId({ commit }, payload = {}) {
      commit("setDossierOfferExpandedId", payload.dossierOfferId);
    },
    disabledCompareMode({ commit }) {
      commit("setCompareMode", false);
    },
  },
  getters: {
    getShowLoaderOverlay(state) {
      return state.showLoaderOverlay;
    },
    getLoaderOverlayText(state) {
      return state.loaderOverlayText;
    },
    getOfferEditMode(state) {
      return state.offerEditMode;
    },
    getDossierOfferExpanded(state) {
      return state.dossierOfferExpandedId;
    },
    getCompareMode(state) {
      return state.compareMode;
    },
  },
};
