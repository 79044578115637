import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  uploadFile(dossierOfferInsurerCommunicationId, formData) {
    const params = {
      dossierOfferInsurerCommunicationId,
    };
    return axios.post(
      `${links.DOSSIER_OFFER_INSURER_COMMUNICATION_FILES_LINK()}?${qs.stringify(params)}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  deleteFile(dossierOfferInsurerCommunicationId, filename) {
    const params = {
      dossierOfferInsurerCommunicationId,
      filename,
    };
    return axios.delete(
      `${links.DOSSIER_OFFER_INSURER_COMMUNICATION_FILES_LINK()}?${qs.stringify(params)}`
    );
  },
  getFilePublic(signatureToVerify, dossierAccessHash, filename) {
    const params = {
      file: filename,
      sign: signatureToVerify,
    };
    window.open(
      `${links.DOSSIER_OFFER_INSURER_COMMUNICATION_FILES_PUBLIC_LINK(
        dossierAccessHash
      )}?${qs.stringify(params)}`
    );
  },
  generateQuestionnaire(dossierOfferInsurerCommunicationId) {
    const params = {
      dossierOfferInsurerCommunicationId,
    };
    return axios.post(
      `${links.DOSSIER_OFFER_INSURER_COMMUNICATION_FILES_GENERATE_QUESTIONNAIRE()}?${qs.stringify(
        params
      )}`
    );
  },
};
