import DossierOffer from "../models/DossierOffer";
import API from "@/api/dossierOffers";
import Insurer from "../models/Insurer";
import DossierOfferSet from "../models/DossierOfferSet";

function getDefaultState() {
  return {};
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data, payload.opts).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createAPI(_, payload) {
      return API.create(payload).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },

    uploadOfferFile(_, payload) {
      return API.uploadOfferFile(payload.id, payload.dossierId, payload.formData).then(response => {
        DossierOffer.insertOrUpdate({
          data: response.data,
        });
      });
    },
    deleteOfferFile(_, payload) {
      return API.deleteOfferFile(payload.id, payload.filename).then(response => {
        DossierOffer.insertOrUpdate({
          data: response.data,
        });
      });
    },

    uploadPrepricingFile(_, payload) {
      return API.uploadPrepricingFile(payload.id, payload.dossierId, payload.formData).then(
        response => {
          DossierOffer.insertOrUpdate({
            data: response.data,
          });
        }
      );
    },
    deletePrepricingFile(_, payload) {
      return API.deletePrepricingFile(payload.id, payload.data).then(response => {
        DossierOffer.insertOrUpdate({
          data: response.data,
        });
      });
    },
    getDossierOffer(_, payload) {
      return API.getDossierOffer(payload).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    getRequestedByDossier(_, payload) {
      return API.getRequestedDossierOffers(payload).then(res => {
        Insurer.insertOrUpdate({
          data: res.data.insurers,
        });
        DossierOffer.insertOrUpdate({
          data: res.data.dossierOffers,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id).then(() => {
        DossierOffer.delete(payload.id);
      });
    },
    acceptAsAgencyAPI(_, payload) {
      return new Promise((resolve, reject) => {
        API.acceptAsAgency(payload.dossierOfferId, payload.totalRequired, payload.currentRequired)
          .then(res => {
            DossierOffer.insertOrUpdate({
              data: res.data,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    updateOptionsOrderInStore(_, payload) {
      DossierOffer.update({
        where: payload.dossierOfferId,
        data: {
          [payload.listName]: payload.list,
        },
      });
    },
    initOffer(_, payload) {
      return API.initOffer(payload.id).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    sendFilesOnDemand(_, payload) {
      return API.sendFilesOnDemand(payload.dossierOfferId).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    sendOffer(_, payload) {
      return API.sendOffer(payload).then(res => {
        DossierOfferSet.insertOrUpdate({
          data: res.data,
        });
        DossierOffer.insertOrUpdate({ data: res.data.dossierOffers[0] });
      });
    },
    createVersion(_, payload) {
      return API.createVersion(payload).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    cancelVersion(_, payload) {
      return API.cancelVersion(payload.dossierOfferId, payload.versionUid).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    saveOffer(_, payload) {
      return API.saveOffer(payload).then(res => {
        DossierOffer.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    get: state => (id, versionUid) => {
      if (versionUid) {
        if (state.data[id]) {
          const dossierOffer = state.data[id];
          if (dossierOffer.versions && dossierOffer.versions.length > 0) {
            const version = dossierOffer.versions.find(item => item.uid === versionUid);

            if (version) {
              return {
                ...JSON.parse(JSON.stringify(state.data[id])),
                ...JSON.parse(JSON.stringify(version)),
              };
            }
          }
        }
      }

      return state.data[id] ? { ...state.data[id] } : null;
    },
    getForDossierOfferSet: () => dossierOfferSetId => {
      let dossierOffers = DossierOffer.query()
        .where(item => {
          return item.dossierOfferSetId === dossierOfferSetId;
        })
        .with("insurer")
        .get();

      dossierOffers.sort((item1, item2) => {
        let price1 = item1.offerData.price;
        let price2 = item2.offerData.price;
        if (item1.offerData.refused && (item2.offerData.on_demande || price2)) {
          return 1;
        } else if (item1.offerData.on_demande && price2) {
          return 1;
        } else if (item1.offerData.on_demande && item2.offerData.refused) {
          return -1;
        } else if (price1 && (item2.offerData.on_demande || item2.offerData.refused)) {
          return -1;
        } else if (price1 && price2) {
          if (
            item1.insurer &&
            item1.insurer.promoted &&
            (!item2.insurer || !item2.insurer.promoted)
          ) {
            return -1;
          } else if (
            (!item1.insurer || !item1.insurer.promoted) &&
            item2.insurer &&
            item2.insurer.promoted
          ) {
            return 1;
          }

          if (price1 < price2) {
            return -1;
          } else if (price1 > price2) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
      return dossierOffers;
    },
    getByAccessHash: () => accessHash => {
      return DossierOffer.query().where("accessHash", accessHash).first();
    },
    getOptionsForDossierOffer: () => (dossierOfferId, optionType) => {
      const item = DossierOffer.query().find(dossierOfferId);
      return item[optionType];
    },
    getAllOptionsForDossierOffer: () => dossierOfferId => {
      const item = DossierOffer.query().find(dossierOfferId);
      return [
        ...(item.optionsIncluded ? item.optionsIncluded : []),
        ...(item.optionsSelected ? item.optionsSelected : []),
        ...(item.optionsProposed ? item.optionsProposed : []),
        ...(item.offerData.extraData ? item.offerData.extraData : []),
      ];
    },
  },
};
