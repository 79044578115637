import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(accessHash) {
    let params = {};
    if (accessHash) {
      params["accessHash"] = accessHash;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_QUESTIONNAIRES_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  update(id, data) {
    return axios.put(links.DOSSIER_QUESTIONNAIRE_LINK(id), data);
  },
  generatePdf(id) {
    return axios.post(links.DOSSIER_QUESTIONNAIRE_LINK_GENERATE_PDF(id));
  },
  sendQuestionnaire(id) {
    return axios.post(links.SENDQUESTIONNAIRE(id));
  },
  generateOnlyPdf(id) {
    return axios.get(links.DOSSIER_QUESTIONNAIRE_GENERATE_PDF(id));
  },
  updateProgreesAcceptOffer(id, data) {
    return axios.put(links.DOSSIER_QUESTIONNAIRE_UPDATE_PROGRESS_LINK(id), data);
  },
};
