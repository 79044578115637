import axios from "axios";
import links from "@/links";

export default {
  activateAccountCheckToken(data) {
    return axios.post(links.ACTIVATE_ACCOUNT_CHECK_TOKEN(), data);
  },
  activateAccountUpdateCredentials(data) {
    return axios.post(links.ACTIVATE_ACCOUNT_UPDATE_CREDENTIALS(), data);
  },

  resetPasswordRequest(data) {
    return axios.post(links.RESET_PASS_REQUEST(), data);
  },
  resetPasswordCheckToken(data) {
    return axios.post(links.RESET_PASS_CHECK_TOKEN(), data);
  },
  resetPasswordUpdateCredentials(data) {
    return axios.post(links.RESET_PASS_UPDATE_CREDENTIALS(), data);
  },

  getCurrentUser() {
    return axios.get(links.CURRENT_USER());
  },
  updateCurrentUser(data) {
    return axios.put(links.CURRENT_USER(), data);
  },
  checkUniqueEmail(email) {
    return axios.get(links.CHECK_UNIQUE_EMAIL(email));
  },
  changePassword(data) {
    return axios.put(links.CHANGE_PASSWORD(), data);
  },

  checkChangeEmail(dossierId, currentEmail, originalEmail) {
    return axios.get(links.CHECK_CHANGE_EMAIL(dossierId, currentEmail, originalEmail));
  },
  clientSpaceInfo() {
    return axios.get(links.CLIENT_SPACE_INFO());
  },
  contactResetPassword(item) {
    return axios.post(links.CLIENT_CONTACT_RESET_PASSWORD(), item);
  },
};
