import DossierQuestionnaire from "../models/DossierQuestionnaire";
import API from "@/api/dossierQuestionnaires";
import DossierAdditionalInfo from "../models/DossierAdditionalInfo";
import Dossier from "../models/Dossier";

export default {
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.accessHash).then(res => {
        DossierQuestionnaire.insertOrUpdate({
          data: res.data.questionnaire,
        });
        Dossier.insertOrUpdate({
          data: res.data.dossier,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(res => {
        DossierQuestionnaire.insertOrUpdate({
          data: res.data,
        });
      });
    },
    generatePdf(_, payload) {
      return API.generatePdf(payload.id).then(res => {
        DossierQuestionnaire.insertOrUpdate({
          data: res.data.data,
        });
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data.additionalInfo,
        });
      });
    },
    sendQuestionnaire(_, payload) {
      return new Promise((resolve, reject) => {
        API.sendQuestionnaire(payload.id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    generateOnlyPdf(_, payload) {
      return API.generateOnlyPdf(payload.id).then(res => {
        DossierQuestionnaire.insertOrUpdate({
          data: res.data.data,
        });
        DossierAdditionalInfo.insertOrUpdate({
          data: res.data.additionalInfo,
        });
      });
    },
    updateProgreesAcceptOffer(_, payload) {
      return new Promise((resolve, reject) => {
        API.updateProgreesAcceptOffer(payload.hash, payload.data)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getByDossier: () => dossierId => {
      return DossierQuestionnaire.query().where("dossierId", Number(dossierId)).first();
    },
    getByHash: () => accessHash => {
      return DossierQuestionnaire.query().where("accessHash", accessHash).first();
    },
  },
};
