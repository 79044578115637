import Company from "../models/Company";
import API from "@/api/companies";
import { COMPANIES_ITEMS_PER_PAGE } from "@/constants/company";

function getDefaultState() {
  return {
    activeSearchTerm: "",
    gdpr: null,

    paginationOptions: {},
    itemsPerPage: COMPANIES_ITEMS_PER_PAGE,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, activeSearchTerm) {
      state.activeSearchTerm = activeSearchTerm;
    },

    setGdpr(state, gdpr) {
      state.gdpr = gdpr;
    },

    listCallPerformed(state, { page, totalItems }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
      });
    },
    resetPaginationOptions(state) {
      Object.keys(state.paginationOptions).forEach(key => {
        delete state.paginationOptions[key];
      });
    },
  },
  actions: {
    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },

    setGdpr({ commit }, gdpr) {
      commit("setGdpr", gdpr);
    },

    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },

    getCompanies({ commit, state }, payload) {
      return API.getCompanies(
        payload.search,
        payload.gdpr,
        payload.pagination,
        payload.page,
        state.itemsPerPage
      ).then(res => {
        Company.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data).then(response => {
        Company.insertOrUpdate({
          data: response.data,
        });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data).map(item => ({ ...item }));
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getGdpr(state) {
      return state.gdpr;
    },
    getPage: state => {
      return state.paginationOptions.page;
    },
    getTotalItems: state => {
      return state.paginationOptions.totalItems;
    },
    getCurrentItemsInStore: state => {
      return Object.values(state.data).length;
    },
  },
};
