import { Model } from "@vuex-orm/core";

export default class Insurer extends Model {
  static get entity() {
    return "insurers";
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      nationality: this.attr(null),
      pricingType: this.attr(null),
      logo: this.attr(null),
      representative: this.attr(null),
      identifier: this.attr(null),
      email: this.attr(null),
      active: this.attr(null),
      promoted: this.attr(null),
      additionalProducts: this.attr(null),
      ipidFileGeneral: this.attr([]),
      ipidFileHome: this.attr([]),
      ipidFileOther: this.attr([]),
      representativeName: this.attr(null),
      cgFileGeneral: this.attr([]),
      cgFileHome: this.attr([]),
      cgFileOther: this.attr([]),
      secondEmail: this.attr(null),
      pretarificationFileHome: this.attr([]),
      pretarificationFileOther: this.attr([]),
      pretarificationGeneral: this.attr([]),
      questionnaireFile: this.attr([]),
      questionnaireReceptionFile: this.attr([]),
      questionnaireFileHome: this.attr([]),
      questionnaireFileOther: this.attr([]),
      questionnaireReceptionFileHome: this.attr([]),
      questionnaireReceptionFileOther: this.attr([]),
      pvReportsFile: this.attr([]),
      pvReceptionFile: this.attr([]),
      finalCountFile: this.attr([]),
    };
  }
}
