import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(dossierId) {
    let params = {};
    if (dossierId) {
      params["dossierId"] = dossierId;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_COMPANIES_TECHNICAL_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  create(data) {
    return axios.post(links.DOSSIER_COMPANIES_TECHNICAL_LINK(), data);
  },
  update(id, data, opts = {}) {
    // HACK: ..
    const {
      isNewDevis,
      isDeleteDevis,
      isDevis,
      devisUid,
      isNewAdditionalDevis,
      isDeleteAdditionalDevis,
      isAdditionalDevis,
      additionalDevisUid,
      isNewInvoice,
      isDeleteInvoice,
      isInvoice,
      invoiceUid,
      parentDevisUid,
      isDevisChanged,
      isLotsChanged,

      isPhase7,
    } = opts;
    const params = {
      isNewDevis,
      isDeleteDevis,
      isDevis,
      devisUid,
      isNewAdditionalDevis,
      isDeleteAdditionalDevis,
      isAdditionalDevis,
      additionalDevisUid,
      isNewInvoice,
      isDeleteInvoice,
      isInvoice,
      invoiceUid,
      parentDevisUid,
      isDevisChanged,
      isLotsChanged,

      isPhase7,
    };
    const hasParamsSet = Object.keys(params).length > 0;

    return axios.put(
      links.DOSSIER_COMPANY_TECHNICAL_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      data
    );
  },
  updateSyncData(id, data) {
    return axios.put(links.DOSSIER_COMPANY_TECHNICAL_SYNC_LINK(id), data);
  },
  delete(id, opts = {}) {
    const { isPhase7 } = opts;
    const params = { isPhase7 };
    const hasParamsSet = Object.keys(params).length > 0;

    return axios.delete(
      links.DOSSIER_COMPANY_TECHNICAL_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  createByClientAPI(data) {
    return axios.post(links.DOSSIER_COMPANIES_TECHNICAL_BY_CLIENT_LINK(), data);
  },
};
