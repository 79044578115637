import NotificationLog from "../models/NotificationLog";
import API from "@/api/notifications";
import moment from "moment";
import { mappedClientPhasesNames } from "@/constants/dossier.structure";
import { notificationLogReadStatuses, actionNames } from "@/constants/notifications";
import { isAgency, isPartner, isClient } from "@/utils/auth-roles";
import { notificationActionTypes } from "../../../constants/notifications";

function getDefaultState() {
  return {
    activeSearchTerm: "",
    activeDossierStatus: null,
    activeDossierResponsible: null,
    activeNotificationActionType: null,
    activeNotificationPhase: null,
    activeInsurer: null,

    hasUnreadNotifications: false,
    dossierHasUnreadNotifications: false,

    hasMoreNotifications: {},
    cursorsForPagination: {},
    totalItems: {},
    totalFilteredItems: {},
  };
}

function constructNotificationReadStatusFieldByRole(roles) {
  if (isAgency(roles)) {
    return "readStatusAgency";
  }
  if (isPartner(roles)) {
    return "readStatusPartner";
  }
  if (isClient(roles)) {
    return "readStatusClient";
  }

  console.error("Unrecognized role");
  return "";
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setHasUnreadNotifications(state, value) {
      state.hasUnreadNotifications = value;
    },
    setDossierHasUnreadNotifications(state, value) {
      state.dossierHasUnreadNotifications = value;
    },

    setHasMoreNotifications(state, params) {
      state.hasMoreNotifications[params.status] = params.value;
    },
    setCursorForPagination(state, params) {
      state.cursorsForPagination[params.status] = params.cursor;
    },
    setTotalItems(state, params) {
      state.totalItems[params.status] = params.value;
    },
    setTotalFilteredItems(state, params) {
      state.totalFilteredItems[params.status] = params.value;
    },
    resetPaginationOptions(state, status) {
      if (status) {
        delete state.hasMoreNotifications[status];
        delete state.cursorsForPagination[status];
        delete state.totalItems[status];
        delete state.totalFilteredItems[status];
      } else {
        Object.keys(state.hasMoreNotifications).forEach(key => {
          delete state.hasMoreNotifications[key];
        });
        Object.keys(state.cursorsForPagination).forEach(key => {
          delete state.cursorsForPagination[key];
        });
        Object.keys(state.totalItems).forEach(key => {
          delete state.totalItems[key];
        });
        Object.keys(state.totalFilteredItems).forEach(key => {
          delete state.totalFilteredItems[key];
        });
      }
    },

    setActiveSearchTerm(state, activeSearchTerm) {
      state.activeSearchTerm = activeSearchTerm;
    },
    resetActiveSearchTerm(state) {
      state.activeSearchTerm = "";
    },
    setActiveDossierStatus(state, activeDossierStatus) {
      state.activeDossierStatus = activeDossierStatus;
    },
    setActiveDossierResponsible(state, activeDossierResponsible) {
      state.activeDossierResponsible = activeDossierResponsible;
    },
    setActiveNotificationActionType(state, activeNotificationActionType) {
      state.activeNotificationActionType = activeNotificationActionType;
    },
    setActiveNotificationPhase(state, activeNotificationPhase) {
      state.activeNotificationPhase = activeNotificationPhase;
    },
    setActiveInsurer(state, activeInsurer) {
      state.activeInsurer = activeInsurer;
    },
  },
  actions: {
    fetch({ getters, commit }, payload) {
      const cursorForPagination = getters.getCursorForPagination(payload.status);
      const cursorId = cursorForPagination ? cursorForPagination.id : undefined;
      const cursorOrderByValue = cursorForPagination ? cursorForPagination.actionDate : undefined;

      return API.fetch(
        payload.search,
        payload.status,
        payload.dossierId,
        payload.dossierStatus,
        payload.dossierResponsible,
        payload.notificationActionType,
        payload.notificationPhase,
        payload.insurer,
        cursorId,
        cursorOrderByValue,
        payload.sortOrder,
        payload.sortBy,
        payload.archivedNotificationLog
      ).then(res => {
        const { items, hasMore } = res.data;

        NotificationLog.insertOrUpdate({
          data: items,
        });

        const lastItemInResponse = items[items.length - 1] ? items[items.length - 1] : undefined;
        commit("setCursorForPagination", {
          status: payload.status,
          cursor: lastItemInResponse,
        });

        commit("setHasMoreNotifications", { status: payload.status, value: hasMore });
      });
    },
    countNotifications({ commit }, payload) {
      return API.countNotifications(
        payload.search,
        payload.status,
        payload.dossierId,
        payload.dossierStatus,
        payload.dossierResponsible,
        payload.notificationActionType,
        payload.notificationPhase,
        payload.insurer,
        payload.archivedNotificationLog
      ).then(res => {
        const { count } = res.data;
        if (
          payload.search ||
          payload.dossierStatus ||
          payload.dossierResponsible ||
          payload.notificationActionType ||
          payload.notificationPhase ||
          payload.insurer ||
          payload.archivedNotificationLog
        )
          commit("setTotalFilteredItems", { status: payload.status, value: count });
        else commit("setTotalItems", { status: payload.status, value: count });
      });
    },

    resetPaginationOptions({ commit }, status) {
      commit("resetPaginationOptions", status);
    },

    fetchUnread(_, payload) {
      return API.fetchUnread(payload.dossierId, payload.phase).then(res => {
        NotificationLog.insertOrUpdate({
          data: res.data,
        });
      });
    },
    changeNotificationStatus(_, payload) {
      return API.changeNotificationStatus(payload.notificationLogId, payload.status).then(res => {
        NotificationLog.insertOrUpdate({
          data: res.data,
        });
      });
    },
    changeNotificationsStatuses(_, payload) {
      return API.changeNotificationsStatuses(payload.notificationLogsIds, payload.status).then(
        res => {
          res.data.forEach(notification => {
            NotificationLog.insertOrUpdate({
              data: notification,
            });
          });
        }
      );
    },

    checkUnreadNotifications({ commit }) {
      return API.checkUnreadNotifications().then(res => {
        commit("setHasUnreadNotifications", res.data.hasUnread);
      });
    },
    setHasUnreadNotifications({ commit }, value) {
      commit("setHasUnreadNotifications", value);
    },
    checkDossierUnreadNotifications({ commit }, dossierId) {
      return API.checkUnreadNotifications(dossierId).then(res => {
        commit("setDossierHasUnreadNotifications", res.data.hasUnread);
      });
    },
    setDossierHasUnreadNotifications({ commit }, value) {
      commit("setDossierHasUnreadNotifications", value);
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },
    resetActiveSearchTerm({ commit }) {
      commit("resetActiveSearchTerm");
    },
    setActiveDossierStatus({ commit }, activeDossierStatus) {
      commit("setActiveDossierStatus", activeDossierStatus);
    },
    setActiveDossierResponsible({ commit }, activeDossierResponsible) {
      commit("setActiveDossierResponsible", activeDossierResponsible);
    },
    setActiveNotificationActionType({ commit }, activeNotificationActionType) {
      commit("setActiveNotificationActionType", activeNotificationActionType);
    },
    setActiveNotificationPhase({ commit }, activeNotificationPhase) {
      commit("setActiveNotificationPhase", activeNotificationPhase);
    },

    deleteAllByStatus({ rootGetters }, status) {
      const currentUserRoles = rootGetters["auth/currentUserRoles"];
      const readStatusField = constructNotificationReadStatusFieldByRole(currentUserRoles);

      NotificationLog.delete(item => item[readStatusField] === status);
    },
    setActiveInsurer({ commit }, activeInsurer) {
      commit("setActiveInsurer", activeInsurer);
    },
  },
  getters: {
    getHasUnreadNotifications(state) {
      return !!state.hasUnreadNotifications;
    },
    getDossierHasUnreadNotifications(state) {
      return !!state.dossierHasUnreadNotifications;
    },

    getHasMoreNotifications: state => status => {
      return !!state.hasMoreNotifications[status];
    },
    getCursorForPagination: state => status => {
      return state.cursorsForPagination[status];
    },
    getTotalItems: state => status => {
      return state.totalItems[status];
    },
    getTotalFilteredItems: state => status => {
      return state.totalFilteredItems[status];
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getActiveDossierStatus(state) {
      return state.activeDossierStatus;
    },
    getActiveDossierResponsible(state) {
      return state.activeDossierResponsible;
    },
    getActiveNotificationActionType(state) {
      return state.activeNotificationActionType;
    },
    getActiveNotificationPhase(state) {
      return state.activeNotificationPhase;
    },
    getActiveInsurer(state) {
      return state.activeInsurer;
    },

    // ???: filter by search?
    getMultipleForNotificationList:
      (_state, _getters, _rootState, rootGetters) =>
      (
        searchTermFilter,
        status,
        dossierId,
        dossierStatus,
        dossierResponsible,
        notificationActionType,
        notificationPhase,
        insurer,
        sortOrder = "asc",
        sortBy = "actionDate"
      ) => {
        const currentUserRoles = rootGetters["auth/currentUserRoles"];
        const dossiers = rootGetters["entities/dossiers/getAll"];
        const readStatusField = constructNotificationReadStatusFieldByRole(currentUserRoles);
        let searchTerm = searchTermFilter.toLowerCase();
        let searchByString = false;

        if (searchTerm) {
          if (searchTerm.includes("do-")) {
            dossierId = Number(searchTerm.replace("do-", "").replace(/^0+/, ""));
          } else {
            searchByString = true;
          }
        }
        return Object.values(_state.data)
          .filter(notificationLog => {
            const dossier = notificationLog.dossierId
              ? dossiers.find(item => item.id === notificationLog.dossierId)
              : null;
            let checkReadStatus = notificationLog[readStatusField] === status;
            let checkDossierId = !dossierId || notificationLog.dossierId === dossierId;

            let checkDossierStatus =
              !dossierStatus || (dossier && dossier.status === dossierStatus);
            let checkInsurer =
              !insurer || insurer === -1 || (dossier && dossier.insurerId === Number(insurer));

            let checkResponsible =
              isClient(currentUserRoles) ||
              !dossierResponsible ||
              (dossier &&
                ((dossier.secondResponsibleId &&
                  dossier.secondResponsibleId === dossierResponsible) ||
                  dossier.responsibleId === dossierResponsible ||
                  (dossierResponsible === -1 &&
                    dossier.responsibleId === null &&
                    dossier.secondResponsibleId === null)));

            let checkNotificationType = false;
            let actionDataString = JSON.stringify(notificationLog.actionData);

            checkNotificationType =
              !notificationActionType ||
              (notificationActionType ===
                notificationActionTypes.receive_mailjet_webhook_event.key &&
                actionDataString.includes(actionNames.RECEIVE_MAILJET_WEBHOOK_EVENT));

            if (!checkNotificationType)
              checkNotificationType =
                !notificationActionType ||
                (notificationActionType ===
                  notificationActionTypes.devis_communication_response.key &&
                  (actionDataString.includes(actionNames.DEVIS_COMMUNICATION_MESSAGE) ||
                    actionDataString.includes(actionNames.DEVIS_COMMUNICATION_RESPONSE) ||
                    actionDataString.includes(actionNames.OFFER_REQUESTED) ||
                    actionDataString.includes(actionNames.OFFER_CONTACT)));

            if (!checkNotificationType)
              checkNotificationType =
                !notificationActionType ||
                (notificationActionType ===
                  notificationActionTypes.insurer_communication_message.key &&
                  (actionDataString.includes(actionNames.INSURER_COMMUNICATION_MESSAGE) ||
                    actionDataString.includes(
                      actionNames.DOSSIER_OFFER_INSURER_COMMUNICATION_RESPONSE
                    ) ||
                    actionDataString.includes(actionNames.INSURER_COMMUNICATION_RESPONSE)));

            if (!checkNotificationType)
              checkNotificationType =
                !notificationActionType ||
                (notificationActionType ===
                  notificationActionTypes.tarifs_sent_direct_client_new_dossier.key &&
                  actionDataString.includes(actionNames.TARIFS_SENT_DIRECT_CLIENT_NEW_DOSSIER));

            let checkNotificationPhase = true;
            if (notificationPhase) {
              checkNotificationPhase = false;
              if (rootGetters["auth/isClient"]) {
                checkNotificationPhase = mappedClientPhasesNames[notificationPhase].includes(
                  notificationLog.phase
                );
              } else {
                checkNotificationPhase = notificationLog.phase === notificationPhase;
              }
            }
            let checkSearchTerm =
              !searchByString ||
              (dossier && dossier.id.toString().includes(searchTerm)) ||
              (dossier &&
                !!dossier.contactCompanyName &&
                dossier.contactCompanyName.toLowerCase().includes(searchTerm)) ||
              (dossier &&
                !!dossier.contactFirstname &&
                dossier.contactFirstname.toLowerCase().includes(searchTerm)) ||
              (dossier &&
                !!dossier.contactLastname &&
                dossier.contactLastname.toLowerCase().includes(searchTerm)) ||
              (dossier &&
                !!dossier.contactEmail &&
                dossier.contactEmail.toLowerCase().includes(searchTerm)) ||
              (dossier &&
                !!notificationLog?.actionData?.actionInfo?.eventObject?.email &&
                notificationLog.actionData.actionInfo.eventObject.email
                  .toLowerCase()
                  .includes(searchTerm));
            return (
              checkReadStatus &&
              checkDossierId &&
              checkDossierStatus &&
              checkInsurer &&
              checkResponsible &&
              checkNotificationType &&
              checkNotificationPhase &&
              checkSearchTerm
            );
          })
          .sort((a, b) => {
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (valueA === valueB) {
              return a.id < b.id ? 1 : -1;
            } else if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(notificationLog => ({ ...notificationLog }));
      },

    getMultipleForNotificationListGrouped:
      (_, getters) =>
      (
        searchTermFilter,
        status,
        dossierId,
        dossierStatus,
        dossierResponsible,
        notificationActionType,
        notificationPhase,
        sortOrder = "asc",
        sortBy = "actionDate"
      ) => {
        const notificationLogs = getters.getMultipleForNotificationList(
          searchTermFilter,
          status,
          dossierId,
          dossierStatus,
          dossierResponsible,
          notificationActionType,
          notificationPhase,
          sortOrder,
          sortBy
        );

        let groupedNotificationLogs = [];
        let lastTimeKey;
        notificationLogs.forEach(notificationLogX => {
          const { actionDate: actionDateX } = notificationLogX;
          const timeKeyX = moment(actionDateX).format("YYYY-MM-DD HH");

          if (lastTimeKey !== timeKeyX) {
            lastTimeKey = timeKeyX;

            const timeKeyXNotificationLogs = notificationLogs.filter(notificationLogY => {
              const { actionDate: actionDateY } = notificationLogY;
              const timeKeyY = moment(actionDateY).format("YYYY-MM-DD HH");

              return timeKeyY === timeKeyX;
            });

            let lastDossiers = [];
            timeKeyXNotificationLogs.forEach(notificationLogZ => {
              const { dossierId } = notificationLogZ;

              if (!lastDossiers.includes(dossierId)) {
                lastDossiers.push(dossierId);

                groupedNotificationLogs.push(
                  timeKeyXNotificationLogs.filter(
                    notificationLog => notificationLog.dossierId === dossierId
                  )
                );
              }
            });
          }
        });

        return groupedNotificationLogs;
      },

    getUnreadTargetedNotifications: (_state, _getters, _rootState, rootGetters) => payload => {
      const { dossierId, model, rowId, field, action } = payload;

      return NotificationLog.query()
        .where(notificationLog => {
          const currentUserRoles = rootGetters["auth/currentUserRoles"];
          const readStatusField = constructNotificationReadStatusFieldByRole(currentUserRoles);

          // ???: filter by phase?
          return (
            notificationLog[readStatusField] === notificationLogReadStatuses.UNREAD &&
            notificationLog.dossierId === dossierId &&
            notificationLog.actionData.model === model &&
            (notificationLog.actionData.rowId
              ? notificationLog.actionData.rowId === rowId
              : true) &&
            (notificationLog.actionData.changed
              ? notificationLog.actionData.changed.includes(field)
              : true) &&
            notificationLog.actionData.action === action
          );
        })
        .get();
    },

    getActiveDossierResponsibleForNotifications: state => dossierId => {
      if (!dossierId) {
        return state.activeDossierResponsible;
      }
      return null;
    },
  },
};
