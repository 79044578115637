import { Model } from "@vuex-orm/core";

export default class DossierArchived extends Model {
  static get entity() {
    return "dossierArchived";
  }

  static fields() {
    return {
      id: this.attr(null),
      dossierId: this.attr(null),
      dossierStatus: this.attr(null),
      clientName: this.attr(null),
      clientEmail: this.attr(null),
      clientPhone: this.attr(null),
      file: this.attr(null),
    };
  }
}
