import DossierESCircuit from "../models/DossierESCircuit";
import API from "@/api/dossierESCircuits";

function getDefaultState() {
  return {};
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    getCircuits(_, payload) {
      return API.getCircuits(payload.dossierId, payload.phase).then(res => {
        DossierESCircuit.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createCircuit(_, payload) {
      return API.createCircuit(
        payload.phase,
        payload.dossierId,
        payload.groupedCheckedFilesWithModels
      ).then(res => {
        DossierESCircuit.insertOrUpdate({
          data: res.data,
        });
      });
    },
    cancelCircuit(_, payload) {
      return API.cancelCircuit(payload.id, payload.reasonKey, payload.comment).then(res => {
        DossierESCircuit.insertOrUpdate({
          data: res.data,
        });
      });
    },
    syncCircuit(_, payload) {
      return API.syncCircuit(payload.id).then(res => {
        DossierESCircuit.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteDossierESCircuit(_, payload) {
      return API.deleteDossierESCircuit(payload.id).then(() => {
        DossierESCircuit.delete(payload.id);
      });
    },
  },
  getters: {
    getMultipleForCircuitsList: state => (dossierId, phase) => {
      return Object.values(state.data)
        .filter(
          dossierESCircuit =>
            dossierESCircuit.dossierId === dossierId && dossierESCircuit.phase === phase
        )
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.ediSignatureData ? a.ediSignatureData.updatedDate : 0;
          const valueB = b.ediSignatureData ? b.ediSignatureData.updatedDate : 0;
          return valueA > valueB ? -1 : 1;
        })
        .map(dossierESCircuit => ({ ...dossierESCircuit }));
    },
  },
};
