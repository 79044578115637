import axios from "axios";
import links from "@/links";
import qs from "qs";
import { NOTIFICATIONS_ITEMS_PER_PAGE } from "../constants/notifications";

export default {
  fetch(
    search,
    status,
    dossierId,
    dossierStatus,
    dossierResponsible,
    notificationActionType,
    notificationPhase,
    insurer,
    cursorId,
    cursorOrderByValue,
    sortOrder = "asc",
    sortBy = "actionDate",
    archivedNotificationLog
  ) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (dossierId) {
      params["dossierId"] = dossierId;
    }
    if (dossierStatus) {
      params["dossierStatus"] = dossierStatus;
    }
    if (dossierResponsible) {
      params["dossierResponsible"] = dossierResponsible;
    }
    if (notificationActionType) {
      params["notificationActionType"] = notificationActionType;
    }
    if (notificationPhase) {
      params["notificationPhase"] = notificationPhase;
    }
    if (cursorId) {
      params["cursorId"] = cursorId;
    }
    if (cursorOrderByValue) {
      params["cursorOrderByValue"] = cursorOrderByValue;
    }
    if (insurer) {
      params["insurer"] = insurer;
    }
    if (archivedNotificationLog) {
      params["archivedNotificationLog"] = archivedNotificationLog;
    }
    params[`order[${sortBy}]`] = sortOrder;

    params["itemsPerPage"] = NOTIFICATIONS_ITEMS_PER_PAGE;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.NOTIFICATIONS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  countNotifications(
    search,
    status,
    dossierId,
    dossierStatus,
    dossierResponsible,
    notificationActionType,
    notificationPhase,
    insurer,
    archivedNotificationLog
  ) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (dossierId) {
      params["dossierId"] = dossierId;
    }
    if (dossierStatus) {
      params["dossierStatus"] = dossierStatus;
    }
    if (dossierResponsible) {
      params["dossierResponsible"] = dossierResponsible;
    }
    if (notificationActionType) {
      params["notificationActionType"] = notificationActionType;
    }
    if (notificationPhase) {
      params["notificationPhase"] = notificationPhase;
    }
    if (insurer) {
      params["insurer"] = insurer;
    }
    if (archivedNotificationLog) {
      params["archivedNotificationLog"] = archivedNotificationLog;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.NOTIFICATIONS_COUNT() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },

  fetchUnread(dossierId, phase) {
    let params = {};
    if (dossierId) {
      params["dossierId"] = dossierId;
    }
    if (phase) {
      params["phase"] = phase;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.UNREAD_NOTIFICATIONS() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  changeNotificationStatus(notificationLogId, status) {
    return axios.put(links.CHANGE_NOTIFICATION_STATUS(notificationLogId), { status });
  },
  changeNotificationsStatuses(notificationLogsIds, status) {
    return axios.put(links.CHANGE_NOTIFICATIONS_STATUSES(), { notificationLogsIds, status });
  },

  checkUnreadNotifications(dossierId) {
    let params = {};
    if (dossierId) {
      params["dossierId"] = dossierId;
    }
    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.CHECK_UNREAD_NOTIFICATIONS() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
};
