const userRoles = Object.freeze({
  SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ADMIN: "ROLE_ADMIN",
  RESPONSIBLE: "ROLE_RESPONSIBLE",
  RESPONSIBLE_ASSIGNER: "ROLE_RESPONSIBLE_ASSIGNER",
  EMPLOYEE: "ROLE_EMPLOYEE",
  PARTNER: "ROLE_PARTNER",
  CLIENT: "ROLE_CLIENT",
  USER: "ROLE_USER",
});

const userRolesNames = Object.freeze({
  ROLE_SUPER_ADMIN: "Super Administrateur",
  ROLE_ADMIN: "Administrateur",
  ROLE_RESPONSIBLE: "Gestionnaire",
  ROLE_RESPONSIBLE_ASSIGNER: "Gestionnaire attributeur",
  ROLE_EMPLOYEE: "Employé",
  ROLE_PARTNER: "Apporteur",
  ROLE_CLIENT: "Client",
  ROLE_USER: "Utilisateur",
});

const userTypes = Object.freeze({
  PRIVATE: { key: "private", name: "Particulier" },
  PROFESSIONAL: { key: "professional", name: "Professionnel" },
});

const insurerCommunicationDestinations = Object.freeze({
  CLIENT: { key: "client", name: "le client" },
  PARTNER: { key: "partner", name: "l'apporteur" },
  CLIENT_PARTNER: { key: "client_partner", name: "le client et l'apporteur" },
});

export { userRoles, userTypes, userRolesNames, insurerCommunicationDestinations };
