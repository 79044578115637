const availableProducts = Object.freeze({
  DOMMAGE_OUVRAGE: { key: "dommage_ouvrage", name: "Dommage ouvrage" },
});

const statuses = Object.freeze({
  FINALIZED: {
    key: "finalized",
    name: "Finalisé",
    info: "Vous avez finalisé le dossier de réception. Le chantier est assuré definitivement",
  }, // phase 8
  RECEPTION: {
    key: "reception",
    name: "Réception",
    info: "La date de réception prévisionnelle est dépassée. Le dossier de réception est en cours de constitution.",
  },
  SUBSCRIBED: {
    key: "subscribed",
    name: "Souscrit",
    info: "Votre assurance est souscrite, en attendant la réception des travaux",
  }, // phase 6, 7
  IN_PROGRESS: {
    key: "in_progress",
    name: "En cours",
    info: "Votre dossier de souscription est en cours.",
  }, // phase 2, 3, 4, 5
  ACCEPTED: { key: "accepted", name: "Accepté", info: "Vous avez accepté une offre d'assurance" }, // phase 1
  PRICING: {
    key: "pricing",
    name: "Tarification",
    info: "Le dossier est en cours de tarification",
  }, // phase a, b, c

  CANCELED: { key: "canceled", name: "Annulé", info: "Vous avez annulé le dossier" },
  REJECTED: { key: "rejected", name: "Rejetée", info: "Le dossier a été refusé par l'assureur" },
  TRASHED: { key: "trashed", name: "Supprimé", info: "Le dossier a été supprimé" },
});

const restrictedPartnerStatuses = Object.freeze([
  statuses.TRASHED.key,
  statuses.REJECTED.key,
  statuses.PRICING.key,
]);

const restrictedClientStatuses = Object.freeze([
  statuses.PRICING.key,
  statuses.TRASHED.key,
  statuses.REJECTED.key,
]);

const actionStatuses = Object.freeze({
  SUBSCRIPTION_SUBMITTED: { key: "subscription_submitted", name: "subscription submitted" },
  OFFER_SENT: { key: "offer_sent", name: "offer sent" },
  OFFER_ACCEPTED: { key: "offer_accepted", name: "offer accepted" },
  OFFER_REJECTED: { key: "offer_rejected", name: "offer rejected" },
  SIGNATURE_REQUESTED: { key: "signature_requested", name: "signature requested" },
  SIGNATURE_MADE: { key: "signature_made", name: "signature made" },
  PAYMENT_REQUESTED: { key: "payment_requested", name: "payment requested" },
  PAYMENT_MADE: { key: "payment_made", name: "payment made" },
});

const offerChannelTransmissions = Object.freeze({
  EMAIL: { key: "email", name: "envoyé par email" },
});

const offerStatuses = Object.freeze({
  DEVIS: { key: "devis", name: "devis" }, //after subscription - show in phase B
  OFFER: { key: "offer", name: "offer" }, //after request offer (email) - show in phase C - USER ACTION
  PREPARING_OFFER: { key: "preapring_offer", name: "preparing_offer" }, //edit and offer - show in phase C
  REQUESTED_AGENCY: { key: "requested_agency", name: "requested_agency" }, // demand more - AGENCY ACTION
  REQUESTED_PARTNER: { key: "requested_partner", name: "requested_partner" }, // demand more - PARTNER ACTION
  INTERESTED: { key: "interested", name: "interested" }, //contactMe - USER ACTION
  REQUESTED: { key: "requested", name: "requested" }, // demand more - USER ACTION
  ACCEPTED: { key: "accepted", name: "accepted" }, // accept offer - show in phase 1
  REJECTED: { key: "rejected", name: "rejected" },

  OFFER_SENT: { key: "offer_sent", name: "offer_sent" },
  OFFER_SAVED: { key: "offer_saved", name: "Offer saved" },
  OFFER_EDIT: { key: "offer_edit", name: "Offer edit" },
  OFFER_DEMANDED_INSURER: { key: "offer_demanded_insurer", name: "Offer demanded insurer" },
  OFFER_CONTACT_REQUEST: { key: "offer_contact_request", name: "Offer contact request" },
});

const offerSetStatuses = Object.freeze({
  NOT_SENT: { key: "not_sent", name: "not_sent" },
  SAVED_NOT_SENT: { key: "saved_not_sent", name: "saved_not_sent" },
  SENT: { key: "sent", name: "sent" },
});

const dossierRowStatuses = Object.freeze({
  EXCLUDED: { key: "excluded", name: "Exclu" },
  NOT_SUBMITTED: { key: "not_submitted", name: "Non soumis" },
  IN_VALIDATION: { key: "in_validation", name: "Validation" },
  VALID: { key: "valid", name: "Validé" },
  INVALID: { key: "invalid", name: "Invalide" },
});

const dossierMissionRowStatuses = Object.freeze({
  NOT_SUBMITTED: { key: "not_submitted", name: "Non soumise" },
  PENDING: { key: "pending", name: "Renseigner" },
  VALID: { key: "valid", name: "Validé" },
  INVALID: { key: "invalid", name: "Invalide" },
});

const dossierMissionDocumentRowStatuses = Object.freeze({
  UNPAID: { key: "unpaid", name: "Non payé" },
  PAID: { key: "paid", name: "Payé" },
});

const dossierRowStatusesFull = Object.freeze({
  EXCLUDED: { key: "excluded", name: "Exclu" },
  NOT_SUBMITTED: { key: "not_submitted", name: "Non soumis" },
  IN_VALIDATION: { key: "in_validation", name: "Validation" },
  VALID_MISSING: { key: "valid_missing", name: "Valide manquant" },
  VALID: { key: "valid", name: "Validé" },
  INVALID: { key: "invalid", name: "Invalide" },
});

const invalidDossierRowStatuses = Object.freeze({
  INVALID: { key: "invalid", name: "Invalid" },
  UNPAID: { key: "unpaid", name: "Non payé" },
});

const DOSSIERS_ITEMS_PER_PAGE = 5;

const agencyCompanies = Object.freeze({
  MP: { key: "mp", name: "MP" },
  SAS: { key: "sas", name: "SAS" },
});

const questionnairesStatuses = Object.freeze({
  PENDING: { key: "pending", name: "En attente" },
  SUBMITTED: { key: "submitted", name: "Soumis" },
});

const invalidDocReasons = Object.freeze({
  GENERAL: {
    1: { text: "Page(s) manquante(s)" },
    2: { text: "Illisible" },
    3: { text: "Coupé" },
    4: { text: "Ce document vous sera transmis" },
  },
  DEVIS: {
    1: { text: "A votre nom (Maitre d'ouvrage)" },
    2: { text: "Avec l'adresse du chantier concerné" },
    3: { text: "Numéro SIREN manquant" },
    4: { text: "Précisant fourniture et pose" },
    5: { text: "Avec le montant TTC" },
    6: { text: "Avec le descriptif corrigé et le montant total à prendre en compte" },
    7: {
      text: "Ensemble du clos et couvert. Cet intervenant n’est pas « constructeur de maison individuelle ». Il ne peut pas réaliser l’ensemble de ces lots sur une même maison",
    },
    8: { text: "Sans le(s) lots retiré(s) non assuré(s)" },
    9: {
      text: "Le total des factures ne correspond pas au montant des devis : il convient de rajouter les devis modificatifs",
    },
    10: { text: "Le devis détaillé relatif à la facture de l’intervenant est manquant" },
  },
  ATTESTATION: {
    1: {
      text: "L’attestation d’assurance n’est pas conforme : l’entreprise n’est pas assurée pour le lot qu’elle a réalisé",
    },
    2: {
      text: "Celle reçue n’est pas valable à la Date d’Ouverture du Chantier (et non à la date d’exécution des travaux)",
    },
    3: { text: "Avec le N° SIREN de la société identique à celui du devis" },
    4: {
      text: "Partie Responsabilité Civile Professionnelle ; celle reçue est pour la Décennale uniquement ; nous avons besoin des deux parties",
    },
    5: {
      text: "Partie Décennale ; celle reçue est pour la Responsabilité Civile Professionnelle uniquement ; nous avons besoin des deux parties",
    },
    6: { text: "Avec la signature de l'assureur" },
    7: {
      text: "Attestation nominative de chantier. Ce document est à demander par l’entreprise à son assureur, sous présentation du devis réalisé pour votre chantier.",
    },
  },
  CONTRACT_REPORT: {
    1: { text: "Signé des 2 parties (MOA et MOE)" },
    2: { text: "A votre nom (Maitre d'ouvrage)" },
    3: { text: "Numéro SIREN manquant" },
    4: { text: "Portant sur la totalité des travaux réalisés" },
  },
  DECLARATION: {
    1: { text: "Complété : coordonnées, date, superficie" },
    2: { text: "Pour la totalité des travaux" },
    3: { text: "Pour la tranche de travaux à garantir" },
    4: { text: "Validée et tamponnée par la mairie" },
  },

  DAACT: {
    1: {
      text: "Elle doit être déposée pour la totalité/par tranche des travaux conformément à la Déclaration d’Ouverture de Chantier",
    },
    2: { text: "Elle doit être signée par le maître d’ouvrage et tamponnée par la MAIRIE" },
    3: { text: "Elle doit être signée par le maître d’œuvre" },
    4: { text: "Elle doit être tamponnée par la MAIRIE" },
    5: {
      text: "Elle est différente du contrat : merci de nous adresser le certificat d’adressage de la MAIRIE",
    },
    6: { text: "Des pages sont manquantes" },
  },
  DECOMPTE: {
    1: {
      text: "Le décompte général définitif est absent : le dossier ne peut donc pas être instruit.",
    },
    2: { text: "Le numéro de contrat est absent" },
    3: { text: "Le nom du maitre d’ouvrage est absent" },
    4: { text: "L’adresse de la construction est absente" },
  },
  FACTURES: {
    1: { text: "Illisible ou tronqué" },
    2: { text: "A votre nom (Maitre d'ouvrage)" },
    3: { text: "Avec l'adresse du chantier concerné" },
    4: { text: "Numéro SIREN manquant ou différent du devis" },
  },

  PV_RECEPTION: {
    1: {
      text: "La date de réception est antérieure à la date d’effet du contrat : la garantie ne peut pas être validée.",
    },
    2: {
      text: "Il n’est pas à la bonne date. Une date unique de réception est exigée par cet assureur;",
    },
    3: { text: "Il doit mentionner tous les lots réalisés par l’entreprise. Merci de compléter." },
    4: { text: "Il doit mentionner le nom du Maitre d’ouvrage (vous-même)" },

    5: { text: "Il doit mentionner l’adresse de la construction" },
    6: { text: "Il n’est pas signé par l’entreprise" },
    7: { text: "Il n’est pas signé par le Maitre d’ouvrage (vous-même)" },
    8: { text: "Il n’est pas signé par le maitre d’œuvre/l’architecte" },
  },

  PV_RESERVES: {
    1: { text: "Il n’est pas signé par l’entreprise" },
    2: { text: "Il n’est pas signé par le Maitre d’ouvrage (vous-même)" },
    3: { text: "Il n’est pas signé par le maitre d’œuvre/l’architecte" },
  },
  APPEL_PRIME_COMPLEMENTAIRE: {
    1: {
      text: "Le total des factures est supérieur de plus de 10% du coût prévisionnel des travaux indiqué dans le contrat prévisionnel. Un complément de prime vous sera demandé;",
    },
  },
  ATTESTATION_NOT_COMPANY: {
    1: {
      text: "L’attestation d’assurance n’est pas conforme : l’entreprise n’est pas assurée pour le lot qu’elle a réalisé",
    },
    2: {
      text: "Celle reçue n’est pas valable à la Date d’Ouverture du Chantier (et non à la date d’exécution des travaux)",
    },
    3: { text: "Avec le N° SIREN de la société identique à celui du devis" },
    4: {
      text: "Partie Responsabilité Civile Professionnelle ; celle reçue est pour la Décennale uniquement ; nous avons besoin des deux parties",
    },
    5: {
      text: "Partie Décennale ; celle reçue est pour la Responsabilité Civile Professionnelle uniquement ; nous avons besoin des deux parties",
    },
    6: { text: "Avec la signature de l'assureur" },
  },
});

const specialDossiersFilters = Object.freeze({
  INSURER_RETURN_IN_VALIDATION: {
    key: "insurer_return_in_validation",
    name: "Validation de l'assureur en attente",
  },
  APPEL_PRIME_UNPAID: { key: "appel_prime_unpaid", name: "Appel de prime impayé" },
  INSURER_RETURN_RECEPTION_IN_VALIDATION: {
    key: "insurer_return_reception_in_validation",
    name: "Validation de l'assureur Réception en attente",
  },
});

const changeUserEmailForDossier = Object.freeze({
  THIS_DOSSIER: {
    key: "this_dossier",
  },
  ALL_DOSSIERS: { key: "all_dossiers" },
});

const paymentMode = Object.freeze({
  CHECK: { key: "check", name: "Chèque" },
  COMPANY: { key: "company", name: "Compagnie" },
  PAYMENT: { key: "payment", name: "Virement" },
});

const statisticalStatus = Object.freeze({
  IN_PROGRESS: { key: "in_progress", name: "EN COURS" },
  SUBSCRIBED: { key: "subscribed", name: "SOUSCRITS" },
  PAID: { key: "paid", name: "PAYÉ" },
  CANCELED: { key: "canceled", name: "ANNULÉ" },
});

const devisTypePrice = Object.freeze({
  UP: { key: "up", name: "up" },
  DOWN: { key: "down", name: "down" },
  NEW: { key: "new", name: "new" },
});

const contactCheckedStatuses = Object.freeze({
  TO_CHECK: { key: "to_check", name: "Vérifier" },
  VALID: { key: "valid", name: "Valide" },
  INVALID: { key: "invalid", name: "Invalide" },
});
const rejectReasonAssetFreeze = "Contact vérifié et visé par les mesures de gel des avoirs";
const timerStartTrackingDate = "07-12-2023";

const messagesCommunication = Object.freeze({
  INSURER_RETURN: {
    key: "insurer_return",
    text: `<p>Bonjour,</p><p>Vous trouverez dans le lien ci-dessous les pièces pour le dossier référencé en objet.</p><p>Voici un dossier en vérification.</p><p>Voici un dossier en vérification de missions obligatoires.</p><p>Dossier urgent : Apporteur, Proche réception …</p><p>Merci pour votre retour,</p><p>Nom du Gestionnaire souscription</p>`,
  },
  APPEL_PRIME: {
    key: "appel_prime",
    text: `<p>Bonjour,</p><p>Vous trouverez ci-joint les avis de virement des primes reversées pour le dossier en références.</p><p>Dans l’attente des conditions particulières et attestation(s) prévisionnelle(s).</p><p>Bien cordialement.</p><p>Nom du Gestionnaire souscription</p>`,
  },
});

const statusAppelPrime = Object.freeze({
  NOT_SET: { key: "up", name: "Not Set" },
  PAID: { key: "paid", name: "Paid" },
  UNPAID: { key: "unpaid", name: "Unpaid" },
});

const documentStatusOneDrive = Object.freeze({
  NOT_UPLOAD: { key: "not_upload", name: "Not upload" },
  TO_UPLOAD: { key: "to_upload", name: "To upload" },
  UPLOADED: { key: "uploaded", name: "Uploaded" },
  ERROR: { key: "error", name: "Error" },
});

const actionTakenStatuses = Object.freeze({
  CLIENT: { key: "client", name: "Le client" },
  AGENCY: { key: "agency", name: "L'agence" },
  PARTNER: { key: "partner", name: "L'apporteur" },
});

const georisksStatuses = Object.freeze({
  WEAK: { key: "1", name: "Faible" },
  AVERAGE: { key: "2", name: "Moyen" },
  STRONG: { key: "3", name: "Fort" },
});

const creationStructureStatus = Object.freeze({
  TO_GENERATE: { key: "to_generate", name: "TO GENERATE" },
  IN_GENERATION: { key: "in_generation", name: "IN GENERATION" },
  TO_REGENERATE: { key: "to_regenerate", name: "TO REGENERATE" },
  GENERATED: { key: "generated", name: "GENERATED" },
});

export {
  availableProducts,
  statuses,
  restrictedPartnerStatuses,
  restrictedClientStatuses,
  actionStatuses,
  offerChannelTransmissions,
  offerStatuses,
  offerSetStatuses,
  dossierRowStatuses,
  dossierMissionRowStatuses,
  dossierMissionDocumentRowStatuses,
  DOSSIERS_ITEMS_PER_PAGE,
  agencyCompanies,
  questionnairesStatuses,
  dossierRowStatusesFull,
  invalidDossierRowStatuses,
  invalidDocReasons,
  specialDossiersFilters,
  changeUserEmailForDossier,
  paymentMode,
  statisticalStatus,
  devisTypePrice,
  contactCheckedStatuses,
  rejectReasonAssetFreeze,
  timerStartTrackingDate,
  messagesCommunication,
  statusAppelPrime,
  documentStatusOneDrive,
  actionTakenStatuses,
  georisksStatuses,
  creationStructureStatus,
};
