import axios from "axios";
import links from "@/links";

export default {
  requestTokens(data) {
    return axios.post(links.REQUEST_TOKENS(), data);
  },
  refreshToken(data) {
    return axios.post(links.REFRESH_TOKEN(), data);
  },

  ping() {
    return axios.get(links.PING);
  },
};
