import { breakpoints } from "@/constants/breakpoints";

export default {
  computed: {
    currentWindowWidth() {
      return this.$store.getters["breakpoints/getCurrentWindowWidth"];
    },
    currentBreakpoint() {
      return this.$store.getters["breakpoints/getCurrentBreakpoint"];
    },

    // ...
    screenLtXs() {
      return this.currentWindowWidth < breakpoints.xs;
    },
    screenGteXs() {
      return this.currentWindowWidth >= breakpoints.xs;
    },

    screenLtSm() {
      return this.currentWindowWidth < breakpoints.sm;
    },
    screenGteSm() {
      return this.currentWindowWidth >= breakpoints.sm;
    },

    screenLtMd() {
      return this.currentWindowWidth < breakpoints.md;
    },
    screenGteMd() {
      return this.currentWindowWidth >= breakpoints.md;
    },

    screenLtLg() {
      return this.currentWindowWidth < breakpoints.lg;
    },
    screenGteLg() {
      return this.currentWindowWidth >= breakpoints.lg;
    },

    screenLtXl() {
      return this.currentWindowWidth < breakpoints.xl;
    },
    screenGteXl() {
      return this.currentWindowWidth >= breakpoints.xl;
    },

    screenLtXxl() {
      return this.currentWindowWidth < breakpoints.xxl;
    },
    screenGteXxl() {
      return this.currentWindowWidth >= breakpoints.xxl;
    },
  },
};
