import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  getDossierOfferSets(dossierId) {
    return axios.get(links.DOSSIER_OFFER_SET_BY_DOSSIER_LINK(dossierId));
  },
  duplicate(dossierOfferSetId) {
    return axios.post(links.DOSSIER_OFFER_DUPLICATE(dossierOfferSetId));
  },
  sendPricing(dossierOfferSetId, sendAllOffers = false) {
    const data = {
      sendAllOffers: sendAllOffers,
    };
    return axios.post(links.DOSSIER_OFFER_SET_SEND_PRICING(dossierOfferSetId), data);
  },
  savePricing(dossierOfferSetId) {
    return axios.post(links.DOSSIER_OFFER_SET_SAVE_PRICING(dossierOfferSetId));
  },
  cancelEdit(dossierOfferSetId) {
    return axios.post(links.DOSSIER_OFFER_SET_CANCEL_EDIT(dossierOfferSetId));
  },
  getByDossierOfferSetAccessHash(dossierOfferSetAccessHash) {
    return axios.get(links.DOSSIER_OFFER_SET_BY_ACCESS_HASH(dossierOfferSetAccessHash));
  },

  compareOffers(ids) {
    let params = {
      ids,
    };
    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_OFFER_SET_COMPARE_OFFERS() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  subscriptionData(dossierOfferSetId) {
    return axios.get(links.DOSSIER_OFFER_SET_SUBSCRIPTION_DATA(dossierOfferSetId));
  },
};
