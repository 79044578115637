const modelNames = Object.freeze({
  ASSET_FREEZE_REGISTER: "AssetFreezeRegister",
  COMPANY: "Company",
  COMPANY_DOSSIER_COMPANY_BUILDING: "CompanyDossierCompanyBuilding",
  COMPANY_DOSSIER_COMPANY_TECHNICAL: "CompanyDossierCompanyTechnical",
  DEVIS_COMMUNICATION: "DevisCommunication",
  DOCUMENT_NUMBER: "DocumentNumber",
  DOSSIER: "Dossier",
  DOSSIER_ADDITIONAL_INFO: "DossierAdditionalInfo",
  DOSSIER_ADMINISTRATIVE_DOCUMENT: "DossierAdministrativeDocument",
  DOSSIER_COMPANY_BUILDING: "DossierCompanyBuilding",
  DOSSIER_COMPANY_MATERIAL: "DossierCompanyMaterial",
  DOSSIER_COMPANY_TECHNICAL: "DossierCompanyTechnical",
  DOSSIER_CONTRACT: "DossierContract",
  DOSSIER_ES_CIRCUIT: "DossierESCircuit",
  DOSSIER_ES_TASK: "DossierESTask",
  DOSSIER_EXPORT: "DossierExport",
  DOSSIER_INSURANCE: "DossierInsurance",
  DOSSIER_MISSION: "DossierMission",
  DOSSIER_MISSION_DOCUMENT: "DossierMissionDocument",
  DOSSIER_NOTIFICATION: "DossierNotification",
  DOSSIER_OFFER: "DossierOffer",
  DOSSIER_OFFER_INSURER_COMMUNICATION: "DossierOfferInsurerCommunication",
  DOSSIER_OFFER_SET: "DossierOfferSet",
  DOSSIER_PAYMENT: "DossierPayment",
  DOSSIER_PRE_INSURANCE_PAYMENT: "DossierPreInsurancePayment",
  DOSSIER_QUESTIONNAIRE: "DossierQuestionnaire",
  DOSSIER_SIGNATURE: "DossierSignature",
  DOSSIER_SPECIFIC_DOCUMENT: "DossierSpecificDocument",
  DOSSIER_TRASHED_DOCUMENT: "DossierTrashedDocument",
  GENERAL_SETTING: "GeneralSetting",
  INSURER: "Insurer",
  INSURER_COMMUNICATION: "InsurerCommunication",
  LEAD: "Lead",
  NOTIFICATION_LOG: "NotificationLog",
  SCAN_FILES_QUEUE: "ScanFilesQueue",
  USER: "User",
  USER_ACTION_LOG: "UserActionLog",
});

export { modelNames };
