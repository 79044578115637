import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  getSpaceClientInfo() {
    return axios.get(links.GENERAL_SETTING_SPACE_CLIENT_INFO_LINK());
  },

  updateSpaceClientInfo(data) {
    return axios.put(links.GENERAL_SETTING_SPACE_CLIENT_INFO_LINK(), data);
  },

  uploadGeneralSettingFile(formData) {
    return axios.post(links.GENERAL_SETTING_UPLOAD_FILE_LINK(), formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getSubscriptionFile() {
    return axios.get(links.GENERAL_SETTING_SUBSCRIPTION_FILE_LINK());
  },
  getReceptionFile() {
    return axios.get(links.GENERAL_SETTING_RECEPTION_FILE_LINK());
  },
  deleteGeneralSettingFile(data) {
    return axios.post(links.GENERAL_SETTING_DELETE_FILE_LINK(), data);
  },
  getGeoLocation(data) {
    return axios.get(links.GENERAL_SETTING_GEO_LOCATION_API(data));
  },
  authorizeOneDrive(code) {
    let params = {};
    if (code) {
      params["code"] = code;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.AUTHORIZE_ONE_DRIVE() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  getOneDriveErrorAuthorizationCode() {
    return axios.get(links.ONEDRIVE_ERROR_AUTHORIZATION_CODE());
  },
  getOneDriveItemAndDriveId() {
    return axios.get(links.GENERAL_SETTING_ONE_DRIVE());
  },
  updateOneDriveInfo(data) {
    return axios.put(links.GENERAL_SETTING_ONE_DRIVE(), data);
  },
  getOneDriveIdentifiers(data) {
    return axios.put(links.GENERAL_SETTING_ONE_DRIVE_IDENTIFIERS(), data);
  },
};
