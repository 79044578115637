import axios from "axios";
import links from "@/links";
import qs from "qs";
import { DOSSIER_EXPORT_ITEMS_PER_PAGE } from "../constants/dossierExport";

export default {
  getDossierExports(
    pagination = true,
    page = 1,
    itemsPerPage = DOSSIER_EXPORT_ITEMS_PER_PAGE,
    sortBy = "createdAt",
    sortOrder = "desc",
    type
  ) {
    let params = {};
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }
    params[`order[${sortBy}]`] = sortOrder;
    params["type"] = type;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_EXPORTS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },

  create(data) {
    return axios.post(links.DOSSIER_EXPORTS_LINK(), data);
  },
  delete(id) {
    return axios.delete(links.DOSSIER_EXPORT_LINK(id));
  },

  sendEmail(id) {
    return axios.post(links.DOSSIER_EXPORT_SEND_EMAIL(id));
  },
  sendingOneDrive(id) {
    return axios.post(links.DOSSIER_EXPORT_SEND_ONEDRIVE(id));
  },
};
