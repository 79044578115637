import API from "@/api/dossierOfferInsurerCommunicationFiles";
import DossierOfferInsurerCommunication from "../database/models/DossierOfferInsurerCommunication";

export default {
  namespaced: true,
  actions: {
    uploadFile(_, payload) {
      const { dossierOfferInsurerCommunicationId, formData } = payload;
      return API.uploadFile(dossierOfferInsurerCommunicationId, formData).then(res => {
        DossierOfferInsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteFile(_, payload) {
      const { dossierOfferInsurerCommunicationId, filename } = payload;
      return API.deleteFile(dossierOfferInsurerCommunicationId, filename).then(res => {
        DossierOfferInsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
    generateQuestionnaire(_, payload) {
      const { dossierOfferInsurerCommunicationId } = payload;
      return API.generateQuestionnaire(dossierOfferInsurerCommunicationId).then(res => {
        DossierOfferInsurerCommunication.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
};
