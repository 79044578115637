import { Model } from "@vuex-orm/core";

export default class BackupLogging extends Model {
  static get entity() {
    return "backupLogging";
  }

  static fields() {
    return {
      id: this.attr(null),
      ip: this.attr(null),
      size: this.attr(null),
      serverAvailableSpace: this.attr(null),
      errorLog: this.attr(null),
      status: this.attr(null),
      crtDate: this.attr(null),
    };
  }
}
