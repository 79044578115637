<template>
  <div class="overlay-wrapper" :class="{ 'has-text': !!loaderOverlayText }">
    <div class="overlay" :class="{ 'has-text': !!loaderOverlayText }">
      <AppBigSpinner class="my-0" />
      <div v-if="!!loaderOverlayText" class="overlay-text mt-3">
        {{ loaderOverlayText }}
      </div>
    </div>
  </div>
</template>

<script>
import AppBigSpinner from "./AppBigSpinner";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  components: {
    AppBigSpinner,
  },
  setup() {
    const store = useStore();
    const loaderOverlayText = computed(() => store.getters["general/getLoaderOverlayText"]);
    return { loaderOverlayText };
  },
};
</script>

<style lang="scss" scoped>
.overlay-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.6);
  &.has-text {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.overlay {
  &.has-text {
    max-width: 90%;
    padding: 1rem 2rem;
    background: $color-white;
    box-shadow: $box-shadow-sm;
    border-radius: 1rem;
  }
}

.overlay-text {
  text-align: center;
  font-weight: 600;
  color: $primary;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
