import DossierArchived from "../models/DossierArchived";
import API from "@/api/dossierArchived";

function getDefaultState() {
  return {
    paginationOptions: {},
    itemsPerPage: 10,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    listCallPerformed(state, { page, totalItems }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
      });
    },
  },
  actions: {
    fetchAPI({ commit, state }, payload) {
      return API.fetch(payload.pagination, payload.page, state.itemsPerPage).then(res => {
        DossierArchived.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
        });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data).map(item => ({ ...item }));
    },

    getPage: state => {
      return state.paginationOptions.page;
    },
    getTotalItems: state => {
      return state.paginationOptions.totalItems;
    },
    getCurrentItemsInStore: state => {
      return Object.values(state.data).length;
    },
  },
};
