const breakpoints = Object.freeze({
  // !!!: default: non-existent
  xxs: 0,
  // !!!: default 0
  xs: 390,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});

export { breakpoints };
