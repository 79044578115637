import axios from "axios";
import links from "@/links";
import qs from "qs";
import { DOSSIER_TRASHED_DOCUMENTS_ITEMS_PER_PAGE } from "../constants/dossierTrashedDocuments";

export default {
  fetch(
    pagination = true,
    page = 1,
    itemsPerPage = DOSSIER_TRASHED_DOCUMENTS_ITEMS_PER_PAGE,
    filters = {}
  ) {
    const { dossierId } = filters;

    let params = {};
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (dossierId) {
      params["dossierId"] = dossierId;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIER_TRASHED_DOCUMENTS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
};
