import axios from "axios";
import links from "@/links";
import qs from "qs";
import { BACKUP_ITEMS_PER_PAGE } from "../constants/backup";

export default {
  getBackupLogging(
    pagination = true,
    page = 1,
    itemsPerPage = BACKUP_ITEMS_PER_PAGE,
    sortBy = "crtDate",
    sortOrder = "desc"
  ) {
    let params = {};
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }
    params[`order[${sortBy}]`] = sortOrder;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.BACKUP_LOGGING_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
};
