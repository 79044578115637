import Insurer from "../models/Insurer";
import API from "@/api/insurers";
import { INSURERS_ITEMS_PER_PAGE } from "@/constants/insurers";
import { tarificateur } from "@/constants/tarificateur";

function getDefaultState() {
  return {
    activeSearchTerm: "",

    paginationOptions: {},
    itemsPerPage: INSURERS_ITEMS_PER_PAGE,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, activeSearchTerm) {
      state.activeSearchTerm = activeSearchTerm;
    },

    listCallPerformed(state, { page, totalItems }) {
      Object.assign(state.paginationOptions, {
        page: page || 1,
        totalItems: totalItems || 0,
      });
    },
    resetPaginationOptions(state) {
      Object.keys(state.paginationOptions).forEach(key => {
        delete state.paginationOptions[key];
      });
    },
    deleteCallPerformed(state) {
      state.paginationOptions.totalItems = state.paginationOptions.totalItems - 1;
      state.paginationOptions.page = Object.values(state.data).length / state.itemsPerPage;
    },
  },
  actions: {
    getInsurers({ commit, state }, payload) {
      return API.getInsurers(
        payload.search,
        payload.pagination,
        payload.page,
        state.itemsPerPage,
        payload.sortBy,
        payload.sortOrder
      ).then(res => {
        Insurer.insertOrUpdate({
          data: res.data.items,
        });
        commit("listCallPerformed", {
          page: payload.page,
          totalItems: res.data.totalItems,
        });
      });
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },

    getInsurer(_, payload) {
      return API.getInsurer(payload.id).then(res => {
        Insurer.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteInsurer({ commit }, payload) {
      return API.deleteInsurer(payload.id).then(() => {
        Insurer.delete(payload.id);
        commit("deleteCallPerformed");
      });
    },
    updateAPI(_, payload) {
      return API.put(payload.id, payload.formData).then(response => {
        Insurer.insertOrUpdate({
          data: response.data,
        });
      });
    },
    resetPaginationOptions({ commit }) {
      commit("resetPaginationOptions");
    },
    createAPI(_, payload) {
      return API.create(payload).then(response => {
        Insurer.insertOrUpdate({
          data: response.data,
        });
      });
    },
  },
  getters: {
    get: state => id => {
      return state.data[id] ? { ...state.data[id] } : null;
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },

    getOneForInsurerDetails: () => id => {
      return Insurer.query().whereId(Number(id)).first();
    },

    getMultipleForInsurerList:
      state =>
      (sortBy = "createdAt", sortOrder = "desc") => {
        return Object.values(state.data)
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(dossier => ({ ...dossier }));
      },

    getAlphabetically: state => () => {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.name || "";
          const valueB = b.name || "";
          return valueA > valueB ? 1 : -1;
        })
        .map(dossier => ({ ...dossier }))
        .filter(item => item.active);
    },

    getPage: state => {
      return state.paginationOptions.page;
    },
    getTotalItems: state => {
      return state.paginationOptions.totalItems;
    },
    getCurrentItemsInStore: state => {
      return Object.values(state.data).length;
    },

    getAll(state) {
      return Object.values(state.data).map(item => ({ ...item }));
    },

    getMicAndSamll(state) {
      return Object.values(state.data)
        .map(item => ({ ...item }))
        .filter(
          item =>
            item.identifier === tarificateur.MIC.database_key ||
            item.identifier === tarificateur.SMALL_DO.database_key
        );
    },
  },
};
