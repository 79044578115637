import { lots, metiers } from "@/constants/dossier.structure";

export function getMetierPosition(metierKey) {
  try {
    const mettier = Object.values(metiers).find(item => item.key === metierKey);

    return mettier ? mettier.position : 0;
  } catch (err) {
    console.error(err);
  }
  return "";
}

export function getLotPosition(lotKey) {
  try {
    const lot = Object.values(lots).find(item => item.key === lotKey);

    return lot ? lot.position : 0;
  } catch (err) {
    console.error(err);
  }
  return "";
}
