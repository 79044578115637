import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// eslint-disable-next-line no-undef
const appVersion = __APP_VERSION__; // defined using Vite config
const dsn = import.meta.env.VITE_SENTRY_DSN;
const project = import.meta.env.VITE_SENTRY_PROJECT;

const mode = import.meta.env.MODE;
const isInProduction = import.meta.env.PROD;

function sentryInit(app, router, tracingOrigins) {
  if (!isInProduction) return;

  Sentry.init({
    environment: mode,

    app,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [...tracingOrigins, /^\//],
      }),
    ],

    ignoreErrors: [
      "Non-Error promise rejection captured",
      "Unable to preload CSS",
      "Failed to fetch dynamically imported module",
      "error loading dynamically imported module",
    ],

    tracesSampleRate: 0.1,
    trackComponents: true,
    hooks: ["mount", "update", "destroy"],

    release: `${project}@${appVersion}`,
  });
}

function sentrySetUser({ id, email, roles }) {
  if (!isInProduction) return;

  // ???: roles
  Sentry.setUser({ id, email, roles });
}
function sentryRemoveUser() {
  if (!isInProduction) return;

  Sentry.setUser(null);
}

export { sentryInit, sentrySetUser, sentryRemoveUser };
