import DossierCompanyTechnical from "../models/DossierCompanyTechnical";
import API from "@/api/dossierCompaniesTechnical";
import { getMetierPosition } from "@/hooks/dossierCompany";
import { metiers, missions } from "@/constants/dossier.structure";

function getDefaultState() {
  return {
    countMetiers: 6,
  };
}

function getPosition(data) {
  let position = data.position || 0;
  if (data.position === 0) {
    if (data.rowData.devises.length > 0) {
      if (data.rowData.devises[0].metiers && data.rowData.devises[0].metiers.length > 0) {
        position = getMetierPosition(data.rowData.devises[0].metiers[0]);
      }
      data.rowData.devises.forEach(devis => {
        if (devis.metiers) {
          devis.metiers.forEach(metier => {
            let metierPosition;
            if (metier === metiers.STUDY_OFFICE.key) {
              if (devis.missions?.includes(missions.BET_BA.key)) {
                metierPosition = getMetierPosition(metier);
              } else {
                metierPosition = 5;
              }
            } else {
              metierPosition = getMetierPosition(metier);
            }

            if (position > metierPosition) {
              position = metierPosition;
            }
          });
        }
      });
    }
  }

  return position;
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  actions: {
    fetchAPI(_, payload) {
      return API.fetch(payload.dossierId).then(res => {
        res.data.forEach(item => {
          const displayPosition = getPosition(item);
          item.displayPosition = displayPosition;
          DossierCompanyTechnical.insertOrUpdate({
            data: item,
          });
        });
      });
    },
    createAPI({ state }, payload) {
      return API.create(payload).then(res => {
        const length = DossierCompanyTechnical.query().get().length;
        res.data.displayPosition = length + state.countMetiers;
        DossierCompanyTechnical.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAPI(_, payload) {
      return API.update(payload.id, payload.data, payload.opts).then(res => {
        DossierCompanyTechnical.insertOrUpdate({
          data: res.data,
        });
      });
    },
    removeAPI(_, payload) {
      return API.delete(payload.id, payload.opts).then(() => {
        DossierCompanyTechnical.delete(payload.id);
      });
    },
    updateSyncData(_, payload) {
      return API.updateSyncData(payload.id, payload.data).then(res => {
        DossierCompanyTechnical.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createByClientAPI({ state }, payload) {
      return new Promise((resolve, reject) => {
        API.createByClientAPI(payload)
          .then(res => {
            const length = DossierCompanyTechnical.query().get().length;
            res.data.displayPosition = length + state.countMetiers;
            DossierCompanyTechnical.insertOrUpdate({
              data: res.data,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
  getters: {
    getAll(state) {
      return Object.values(state.data)
        .sort((a, b) => {
          // NOTE: NULLS FIRST
          const valueA = a.displayPosition || 0;
          const valueB = b.displayPosition || 0;
          return valueA > valueB ? 1 : -1;
        })
        .map(item => ({ ...item }));
    },
    getAllActive(_, getters) {
      return getters.getAll.filter(item => item.active);
    },
    countAllActive(_, getters) {
      return getters.getAllActive.length;
    },
    getAllInactive(_, getters) {
      return getters.getAll.filter(item => !item.active);
    },
  },
};
